import React from "react";

import { color, styled } from "@mui/system";
import SingleCheckbox from "./SingleCheckbox";

const MultiCheckboxesStyles = styled("label")(({ theme }) => ({
  fontSize: '14px',
  fontFamily: 'Poppins',
  fontWeight: '400',
  color: theme.palette.gray_300.main,
  display: 'flex',
  gap: '6px',
  "&:has(input[type='checkbox'])": {
    color: theme.palette.blackColor.main,
  }


}));

const CheckboxWithLabel = ({ labelName, ...props }) => {
  return (
    <MultiCheckboxesStyles>
      <SingleCheckbox {...props} />
      {labelName}
    </MultiCheckboxesStyles>
  );
};

export default CheckboxWithLabel;
