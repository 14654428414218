import React, { useEffect, useMemo, useRef, useState } from "react";
import { t } from "i18next";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import _ from "lodash";
import {
  ClickAwayListener,
  Grow,
  MenuList,
  Paper,
  Popper,
} from "@mui/material";
import { fieldTypeMappedObject } from "pages/Admin-Pages/fields-management/Components/AddNewFieldPopup";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { toCamelCase } from "components/ContributePopup/ConttributePopup";
import DetailFormFields from "./components/DetailFormFields";
import MenuIcon from "components/Icons/MenuIcon/MenuIcon";
import CrossIcon from "components/Icons/CrossIcon/CrossIcon";
import ButtonWithFrontIcon from "components/Button/ButtonWithfrontIcon";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import TextfieldWithIcon from "components/Textfield/TextfieldwithIcon";
import ShowDetails from "./components/ShowDetails";
// import DetailFormLeftHeader from "./components/DetailFormLeftHeader";
import { updateContributeDetailsApi } from "../../features/contributionAction";
import ShowcaseCommonFooter from "./components/ShowcaseCommonFooter";
import { activeCompObj } from "../../Contribute";
import DetailStyle from "./DetailsStyle";
import { scrollToElementId } from "utils/scrollToElementId";

export const EditDetailForms = ({
  unMountActiveComponent,
  mountDetailSection,
  contributerData,
  activeComponent,
  addressMapFieldId,
  id,
}) => {
  const [showAll, setShowAll] = useState(false);
  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  const contributeDataArr = useMemo(() => {
    const finalArr = [];
    contributerData?.[0]?.allVisiableField?.map((data2) => {
      if (
        !data2?.visible ||
        data2?.main_title ||
        data2?.main_description ||
        data2?._id === addressMapFieldId ||
        data2?.client_id
      ) {
        return null;
      }

      const data = contributerData?.[0]?.fieldsData?.find(
        (a) => a._id === data2._id,
      );

      const finalObj = {
        name: data2?.name,
        value: data?.field_value?.value || "",
        type: data2?.field_type?.name,
        order_index: data2?.order_index,
      };

      if (data?.field_type?.name === fieldTypeMappedObject.checkbox) {
        const wholeArr = data?.field_default_value?.value || [];
        const checkedArr = data?.field_value?.value?.split(",") || [];
        const checkedSet = new Set(checkedArr);
        const checkedValues = wholeArr
          .filter(({ value }) => checkedSet.has(value))
          .map(({ label }) => label || "")
          .join(",");

        finalObj.value = checkedValues;
      }

      if (data?.field_type?.name === fieldTypeMappedObject.dropDown) {
        const wholeDropArr = data?.field_default_value?.value;
        const dropVal = data?.field_value?.value;
        const foundItem = wholeDropArr.find((item) => item.value === dropVal);
        const option = foundItem ? foundItem.option : "";

        finalObj.value = option;
      }

      if (data?.field_type?.name === fieldTypeMappedObject.customTag) {
        finalObj.value = data?.custom_tag_data_selected?.name;
      }

      if (data?.field_type?.name === fieldTypeMappedObject?.address) {
        let value;
        try {
          value = data?.field_value?.value
            ? JSON?.parse(data?.field_value?.value)?.formatted_address
            : data?.field_value?.value;
        } catch (e) {}
        finalObj.value = value;
      }

      finalArr.push(finalObj);
      return finalObj;
    });

    return finalArr.sort((a, b) => a.order_index - b.order_index);
  }, [addressMapFieldId, contributerData]);

  const itemsToShow = showAll
    ? contributeDataArr
    : contributeDataArr?.slice(0, 8);

  const shouldShowButton = contributeDataArr?.length > 8;

  return (
    <DetailStyle
      className={
        activeComponent && activeComponent !== activeCompObj.detailForm
          ? "addOpacity"
          : ""
      }
    >
      <div className="asstesDetailsBlock">
        {/* <DetailFormLeftHeader
          title={t("details")}
          onEditClick={mountDetailSection}
          onCloseClick={unMountActiveComponent}
          activeComponent={activeComponent === activeCompObj.detailForm}
        /> */}
        <div className="nameValue">
          {itemsToShow?.map((collectiondata, index) => (
            <ShowDetails key={index} collectiondata={collectiondata} />
          ))}
          {shouldShowButton && (
            <TextTag
              variant={textVariants._14px.medium}
              onClick={toggleShowAll}
              className="readAllBtn"
            >
              {showAll ? t("hide") : t("View all")}
            </TextTag>
          )}
        </div>
      </div>
    </DetailStyle>
  );
};

export const SaveDetailForms = ({
  unMountActiveComponent,
  contributerData,
  addressMapFieldId,
  id,
}) => {
  const contributeDataArr = contributerData?.[0]?.fieldsData;
  const allVisibleFields1 = contributerData?.[0]?.allVisiableField;

  const allVisibleFields = allVisibleFields1?.filter(
    (a) => a?._id !== addressMapFieldId,
  );

  // const contributeDataArr = contributeDataArr1?.filter(
  //   (a) => a?._id !== addressMapFieldId,
  // );

  const fieldButtonRef = useRef();
  const dispatch = useDispatch();

  const [openFieldsList, setOpenFieldsList] = useState(false);
  const [searchFieldInput, setSearchFieldInput] = useState("");

  const reqContributeData = useMemo(() => {
    const finalArr = allVisibleFields?.map((data) => {
      return {
        name: data?.name,
        value: data?.field_value?.value,
        type: data?.field_type?.name,
        order_index: data?.order_index,
        _id: data?._id,
      };
    });
    return finalArr?.sort((a, b) => a.order_index - b.order_index);
  }, [allVisibleFields]);

  const initialValues = contributeDataArr?.reduce(
    (acc, { field_value, field_type }) => {
      const camelCaseName = toCamelCase(field_value?.field_id);
      acc[camelCaseName] =
        field_type.name === fieldTypeMappedObject?.address
          ? JSON?.parse(field_value?.value)
          : field_type.name === fieldTypeMappedObject?.checkbox
          ? field_value?.value?.split(",")
          : field_value?.value;
      return acc;
    },
    {},
  );

  const createValidationSchema = (fields) => {
    const shape = {};
    fields?.forEach((fieldsData) => {
      const camelCaseName = toCamelCase(fieldsData?._id);

      if (fieldsData.required_enabled && fieldsData.visible) {
        if (fieldsData?.field_type.name === fieldTypeMappedObject?.checkbox) {
          shape[camelCaseName] = Yup.array()
            .min(1)
            .required(`${fieldsData.name} is required`);
        } else if (fieldsData.field_type.name === fieldTypeMappedObject?.date) {
          shape[camelCaseName] = Yup.string().required(
            `${fieldsData.name} is required`,
          );
        } else {
          shape[camelCaseName] = Yup.string()
            ?.trim()
            .required(`${fieldsData.name} is required`);
        }
      }
    });
    return Yup.object(shape);
  };

  const validationSchema = createValidationSchema(allVisibleFields);

  const onSaveClick = (e) => {
    const obj = formik.values;
    const fields = [];

    for (const key in obj) {
      let value;
      if (Array.isArray(obj[key])) {
        const arr = obj[key]?.map((s) => s?.trim());
        value = arr.join(",");
      } else if (typeof obj[key] === "object" && obj[key] !== null) {
        value = JSON.stringify(obj[key]);
      } else {
        value = obj[key];
      }
      fields.push({
        field_id: key,
        value,
      });
    }

    const body = { fields, id };

    dispatch(updateContributeDetailsApi(body)).then((res) => {
      if (res?.error) {
        return;
      }
      unMountActiveComponent();
    });
  };

  const formik = useFormik({
    validationSchema,
    initialValues,
    enableReinitialize: true,
    onSubmit: onSaveClick,
    validateOnChange: true,
  });

  useEffect(() => {
    // formik.validateForm();
    //eslint-disable-next-line
  }, []);

  const handleFieldClose = () => {
    setOpenFieldsList(false);
    setSearchFieldInput("");
  };

  return (
    <DetailStyle>
      <div className="fieldsEditMain">
        <div className="detailEditTopSection">
          <HeadingTag variant={headingVariants.h3}>{t("fields")}</HeadingTag>
          <ButtonWithFrontIcon
            className={
              openFieldsList
                ? " openFieldsList backButtonClass"
                : "backButtonClass"
            }
            refComp={fieldButtonRef}
            onClick={(e) => {
              e.preventDefault();
              setOpenFieldsList((prev) => !prev);
              setSearchFieldInput("");
            }}
          >
            {openFieldsList ? <CrossIcon /> : <MenuIcon />}
          </ButtonWithFrontIcon>
          {openFieldsList && (
            <Popper
              sx={{ top: "8px !important", zIndex: "9" }}
              open={openFieldsList}
              anchorEl={fieldButtonRef.current}
              placement="bottom-start"
              transition
              disablePortal
              className="assetsFieldItems"
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom-start" ? "left top" : "left bottom",
                  }}
                >
                  <Paper
                    sx={{
                      boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.07);",
                      padding: "16px",
                      borderRadius: "8px",
                    }}
                  >
                    <ClickAwayListener onClickAway={handleFieldClose}>
                      <MenuList
                        sx={{ width: "180px", minHeight: "60px" }}
                        id="composition-menu"
                        aria-labelledby="composition-button"
                        className="dropDownList"
                      >
                        <div className="fieldListSearch">
                          <TextfieldWithIcon
                            placeholder="Search"
                            onChange={(e) => {
                              e.preventDefault();
                              setSearchFieldInput(
                                e?.target?.value?.toLowerCase(),
                              );
                            }}
                          />
                        </div>
                        {reqContributeData?.map((item, index) =>
                          searchFieldInput?.length > 0
                            ? item?.name
                                ?.toLowerCase()
                                ?.includes(searchFieldInput)
                              ? item.type !== fieldTypeMappedObject.address && (
                                  <li
                                    key={index}
                                    onClick={() => {
                                      handleFieldClose();
                                      scrollToElementId(item?.name);
                                    }}
                                    className="uploadSection-menuItems"
                                  >
                                    {
                                      <TextTag
                                        variant={textVariants._14px.medium}
                                      >
                                        {item.name}
                                      </TextTag>
                                    }
                                  </li>
                                )
                              : null
                            : item.type !== fieldTypeMappedObject.address && (
                                <li
                                  key={index}
                                  onClick={() => {
                                    handleFieldClose();
                                    scrollToElementId(item?.name);
                                  }}
                                  className="uploadSection-menuItems"
                                >
                                  {
                                    <TextTag
                                      variant={textVariants._14px.medium}
                                    >
                                      {item.name}
                                    </TextTag>
                                  }
                                </li>
                              ),
                        )}
                        {searchFieldInput?.length > 0 &&
                          reqContributeData?.filter((a) =>
                            a?.name?.toLowerCase()?.includes(searchFieldInput),
                          ).length === 0 && (
                            <TextTag
                              className="fieldError"
                              variant={textVariants._14px.medium}
                            >
                              {t("noDataFound")}
                            </TextTag>
                          )}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          )}
        </div>
        <form onSubmit={formik.handleSubmit}>
          {allVisibleFields?.map((fieldData, index) => {
            return (
              <DetailFormFields
                fieldData={fieldData}
                key={index}
                formik={formik}
              />
            );
          })}
          <ShowcaseCommonFooter
            onCancelClick={unMountActiveComponent}
            onSaveClick={() => {}}
            saveButtonType={"submit"}
            disableSave={!_.isEmpty(formik.errors)}
          />
        </form>
      </div>
    </DetailStyle>
  );
};
