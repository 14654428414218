import {
  Home as HomeIcon,
  AccountBox,
  Description,
  CollectionsOutlined,
} from "@mui/icons-material";
import { FaWpforms } from "react-icons/fa";
import { PiSquaresFourDuotone } from "react-icons/pi";
import { MdOutlineDescription } from "react-icons/md";
import PermMediaIcon from "@mui/icons-material/PermMedia";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import { routeConfigs } from "../../../../utils/routeConfig";
import { permissionIdObject } from "utils/permissions/permissionUtils";
import { checkPermissionFunction } from "utils/permissions/checkPermission";
import i18n from "Assets/i18n/config";

const t = i18n.t;

const SLIDER_LISTING1 = [
  {
    id: 0,
    label: t("dashboard"),
    link: routeConfigs.adminDashboard,
    icon: <HomeIcon />,
  },
  {
    id: 1,
    label: t("contribute"),
    icon: <FileUploadOutlinedIcon />,
    link: routeConfigs.adminAssetsAdd,
    viewPid: permissionIdObject?.contribute?.pid,
  },
  {
    id: 2,
    label: t("accounts"),
    icon: <AccountBox />,
    link: undefined, // link: undefined where children viewPid exist.
    children: [
      {
        label: t("users"),
        link: routeConfigs.adminUserAll,
        viewPid: permissionIdObject?.usersManagement?.pid,
      },
      {
        label: t("rolesAndPermission"),
        link: routeConfigs.adminUserRolesAndPermisiions,
        viewPid: permissionIdObject?.userRolesManagement?.pid,
      },
      {
        label: t("organization"),
        link: routeConfigs.adminRegions,
        viewPid: permissionIdObject?.groupsManagement?.pid,
      },
    ],
  },
  {
    id: 3,
    label: t("digitalAssets"),
    icon: <PiSquaresFourDuotone className="sidebarIcon" />,
    link: routeConfigs.adminAssetType,
    viewPid: permissionIdObject?.assetAdmininstration?.pid,
    children: [
      {
        label: t("fileType"),
        link: routeConfigs.adminAssetType,
      },
      {
        label: t("categories"),
        link: routeConfigs.adminAssetCategory,
      },
      // {
      //   label: t("viewAssets"),
      //   link: routeConfigs.adminAssetsAll,
      // },
    ],
  },
  {
    id: 4,
    label: t("viewAssets"),
    icon: <PermMediaIcon className="sidebarIcon" />,
    link: routeConfigs.adminAssetsAll,
    viewPid: permissionIdObject?.collections?.pid,
  },
  {
    id: 5,
    label: t("collections"),
    icon: <CollectionsOutlined className="sidebarIcon" />,
    link: routeConfigs.adminAssetsCollections,
    viewPid: permissionIdObject?.collections?.pid,
    children: [
      {
        label: t("administration"),
        link: routeConfigs.adminAssetsCollections,
      },
      {
        label: t("uploadedCollections"),
        link: routeConfigs.adminAssetsUploadedCollections,
      },
    ],
  },
  {
    id: 6,
    label: t("taxonomy"),
    icon: <FaWpforms className={"sidebarIcon"} />,
    link: routeConfigs.adminAuiIptcCorefields,
    viewPid: permissionIdObject?.taxonomy?.pid,
  },

  {
    id: 7,
    label: t("pages"),
    icon: <Description />,
    link: routeConfigs.adminPagesAll,
    viewPid: permissionIdObject?.pageManagement?.pid,
    children: [
      {
        label: t("allPages"),
        link: routeConfigs.adminPagesAll,
      },
      {
        label: t("addNewPage"),
        link: routeConfigs.adminPagesAdd,
      },
      {
        label: t("policyPages"),
        link: routeConfigs.adminPagesFooterGeneralPages,
      },
    ],
  },
  {
    id: 8,
    label: t("homePage"),
    icon: <MdOutlineDescription className={"sidebarIcon"} />,
    link: routeConfigs.adminCmschangesFeaturedassets,
    viewPid: permissionIdObject?.cmsManagement?.pid,
    children: [
      {
        label: t("featuredAssets"),
        link: routeConfigs.adminCmschangesFeaturedassets,
      },
      {
        label: t("curatedGallery"),
        link: routeConfigs.adminCmschangesCuratedgallery,
      },
      {
        label: t("searchByFilters"),
        link: routeConfigs.adminCmschangesSearchbyfilters,
      },
      {
        label: t("imageBanner"),
        link: routeConfigs.adminBanners,
      },
      {
        label: t("trendingSearches"),
        link: routeConfigs.adminCmsTrendingSearches,
      },
      { label: t("quickLinks"), link: routeConfigs.adminMarketSegments },
      {
        label: t("footerMenu"),
        link: routeConfigs.adminCmsFooterMenu,
      },
    ],
  },
];

//we are checking if children viewPid is valid
//then putting sliderObj.link equal to children link
export const SLIDER_LISTING = () => {
  SLIDER_LISTING1.forEach((sliderObj) => {
    if (sliderObj?.children) {
      for (const childObj of sliderObj?.children) {
        if (
          childObj?.viewPid !== undefined &&
          checkPermissionFunction(childObj?.viewPid)
        ) {
          sliderObj.link = childObj?.link;
          break;
        }
      }
    }
  });

  return SLIDER_LISTING1;
};
