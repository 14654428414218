import zIndex from "@mui/material/styles/zIndex";
import { maxWidth, styled } from "@mui/system";

const ContributeStyle = styled("div")(({ theme }) => ({
  padding: "48px 64px",
  ".backButtonCard": {
    display: "flex",
    gap: "16px",
    position: "sticky",
    top: "100px",
    zIndex: "1",
    width: "100%",
    // maxWidth: "calc(1440px - 128px)",
    margin: "auto",
    "@media only screen and (width >= 1920px)": {
      maxWidth: "calc(1920px - 128px)",
    }
  },
  ".assetsBlock": {
    display: "flex",
    // gridTemplateColumns: 'repeat(2,1fr)',
    // gap: '144px',
    // maxWidth: "calc(1440px - 128px)",
    maxWidth: "100%",
    margin: "auto",
    justifyContent: "space-between",
    "@media only screen and (width >= 1920px)": {
      maxWidth: "calc(1920px - 128px)",
    },
    ".assetsDetails": {
      flex: "0 0 42%",
      maxWidth: "calc(42% - 23px)",
      "@media only screen and (width >= 1920px)": {
        // maxWidth: "calc(42% - 24px)",
      },
      button: {
        svg: {
          width: "16px",
          height: "16px",
        },
        span: {
          marginLeft: "8px",
          fontSize: "12px",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
        },
      },
    },
    ".asstsFormBlock": {
      flex: "0 0 58%",
      maxWidth: "calc(58% - 40px)",
      position: "relative",
      top: "-24px",
      zIndex: "1",
      " @media only screen and (width >= 1920px)": {
        maxWidth: "calc(50% - 16px)",
      },
      ".titleAndButton": {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginBottom: "32px",
        h3: {
          margin: "0",
        },
        button: {
          padding: "8px 12px",
          height: "24px",
          fontSize: "12px!important",
        },
      },
    },
  },
}));

export default ContributeStyle;
