import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";
import {
  getContributionById,
  uploadAssetsForContribution,
} from "./features/contributionAction";
import {
  EditDetailForms,
  SaveDetailForms,
} from "./Components/DetailForm/DetailForm";
import Description from "./Components/Description/Description";
import { EditKeyword, Keywords } from "./Components/Keywords/Keywords";
import UploadSection from "./Components/UploadSection";
import Layout from "Layout/AdminLayout/Layout";
import { contributerDataState } from "./features/contributionSlice";
import { EditMapLocation, SaveMapLocation } from "components/MapLocationComp";
import ButtonWithFrontIcon from "components/Button/ButtonWithfrontIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";
import ArrowLeftIcon from "components/Icons/ArrowIcons/ArrowLeftIcon";
import MenuIcon from "components/Icons/MenuIcon/MenuIcon";
import ContributeStyle from "./ContributeStyle";
import { fieldTypeMappedObject } from "pages/Admin-Pages/fields-management/Components/AddNewFieldPopup";

export const activeCompObj = {
  description: "description",
  detailForm: "detailForm",
  keywords: "keywords",
  location: "location",
};

const Contribute = () => {
  const location = useLocation();
  const [id, setId] = useState(new URLSearchParams(location?.search).get("id"));
  useEffect(() => {
    setId(new URLSearchParams(location?.search).get("id"));
  }, [location.search]);
  const dispatch = useDispatch();
  const [activeComponent, setActiveComponent] = useState(null);
  const [keyWordArr, setkeyWordArr] = useState([]);
  const [selectedAlbum, setSelectedAlbum] = useState(t("all"));
  const { contributerData } = useSelector(contributerDataState);

  const [defaultLocation, setDefaultLocation] = useState({});

  const addressMapFieldId =
    contributerData?.digital_collections?.[0]?.allVisiableField
      .filter((a) => a?.field_type?.name === fieldTypeMappedObject.address)
      .sort((a, b) => a?.order_index - b?.order_index)?.[0]?._id;

  const addressMapFieldData =
    addressMapFieldId &&
    contributerData?.digital_collections?.[0]?.fieldsData.find(
      (a) => a?._id === addressMapFieldId,
    );

  useEffect(() => {
    const digitalCollections = contributerData?.digital_collections?.[0];

    // Default location setup
    if (addressMapFieldData) {
      let loc;
      try {
        loc = JSON.parse(addressMapFieldData?.field_value?.value);
      } catch (error) {
        console.error("Failed to parse location:", error);
        loc = {}; // Fallback to initial value in case of error
      }
      setDefaultLocation(loc);
    } else {
      setDefaultLocation({});
    }

    // Set keyword array
    setkeyWordArr(digitalCollections?.keyword_data);
  }, [contributerData, activeComponent, addressMapFieldData]);

  useEffect(() => {
    dispatch(getContributionById(id));
  }, [dispatch, id]);

  const unMountActiveComponent = () => {
    setActiveComponent(null);
    dispatch(getContributionById(id));
  };

  const uploadAssetsHandler = async (files) => {
    try {
      const formData = new FormData();
      for await (const file of files) {
        formData.append("files", file);
      }
      formData.append("description", "test data");
      formData.append("type", "collection");
      formData.append("state", "complete");
      selectedAlbum?._id && formData.append("album_id", selectedAlbum?._id);
      dispatch(uploadAssetsForContribution({ id, formData }));
    } catch (e) {
      console.warn(e);
    }
  };

  const RightSectionOfContribute = {
    detailForm: (
      <SaveDetailForms
        activeComponent={activeComponent}
        unMountActiveComponent={unMountActiveComponent}
        contributerData={
          contributerData ? contributerData?.digital_collections : []
        }
        id={id}
        addressMapFieldId={addressMapFieldId}
      />
    ),
    keywords: (
      <Keywords
        contributerData={contributerData}
        activeComponent={activeComponent}
        unMountActiveComponent={unMountActiveComponent}
        keyWordArr={keyWordArr}
        setkeyWordArr={setkeyWordArr}
        id={id}
      />
    ),
    location: addressMapFieldId && (
      <SaveMapLocation
        getSelectedLocation={defaultLocation}
        addressMapFieldId={addressMapFieldId}
        onChange={(e) => {
          setDefaultLocation(e);
        }}
        unMountActiveComponent={unMountActiveComponent}
        id={id}
        contributerData={
          contributerData ? contributerData?.digital_collections : []
        }
      />
    ),
  };

  const navigate = useNavigate();

  return (
    <Layout>
      <ContributeStyle>
        <div className="backButtonCard">
          <ButtonWithFrontIcon
            icon={ArrowLeftIcon}
            className="backButtonClass"
            // startIcon={<KeyboardArrowLeftIcon/>}
            variant="contained"
            onClick={() => navigate("/")}
          >
            <TextTag variant={textVariants._12px.medium}>{t("back")}</TextTag>
          </ButtonWithFrontIcon>
          {/* <ButtonWithFrontIcon
            icon={MenuIcon}
            className="backButtonClass"
            onClick={() => { }}
          /> */}
        </div>
        <div className="container">
          <div className="assetsBlock">
            <div className="assetsDetails">
              <Description
                contributerData={
                  contributerData ? contributerData?.digital_collections : []
                }
                activeComponent={activeComponent}
                unMountActiveComponent={unMountActiveComponent}
                uploadAssetsHandler={uploadAssetsHandler}
                mountDetailSection={() =>
                  setActiveComponent(activeCompObj.detailForm)
                }
              />
              <EditDetailForms
                // show details on left section
                mountDetailSection={() =>
                  setActiveComponent(activeCompObj.detailForm)
                }
                unMountActiveComponent={unMountActiveComponent}
                contributerData={
                  contributerData ? contributerData?.digital_collections : []
                }
                activeComponent={activeComponent}
                addressMapFieldId={addressMapFieldId}
              />
              <EditKeyword
                mountKeyWordSection={() =>
                  setActiveComponent(activeCompObj.keywords)
                }
                unMountActiveComponent={unMountActiveComponent}
                keyWordArr={keyWordArr}
                setkeyWordArr={setkeyWordArr}
                activeComponent={activeComponent}
              />
              {addressMapFieldId && (
                <EditMapLocation
                  mountMapSection={() =>
                    setActiveComponent(activeCompObj.location)
                  }
                  unMountActiveComponent={unMountActiveComponent}
                  activeComponent={activeComponent}
                  selectedLocation={defaultLocation}
                />
              )}
            </div>
            <div className="asstsFormBlock">
              {RightSectionOfContribute[activeComponent] ? (
                RightSectionOfContribute[activeComponent]
              ) : (
                <UploadSection
                  activeComponent={activeComponent}
                  selectedAlbum={selectedAlbum}
                  setSelectedAlbum={setSelectedAlbum}
                  uploadAssetsHandler={uploadAssetsHandler}
                  unMountActiveComponent={unMountActiveComponent}
                />
              )}
            </div>
          </div>
        </div>
      </ContributeStyle>
    </Layout>
  );
};

export default Contribute;
