import { createSlice } from "@reduxjs/toolkit";
import {
  addAssetType,
  countActiveAssetTypeApi,
  deleteAssetType,
  getAllAssetType,
  getAssetType,
  getAssetTypeFields,
  updateAssetType,
  updateAssetTypeStatus,
} from "./typeAction";
const initialState = {
  assetTypes: [],
  activeAssetTypeCount: "",
  fieldValues: [],
  allAssetTypes: [],
};

const assetTypeSlice = createSlice({
  name: "assetType",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAssetType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAssetType.fulfilled, (state, action) => {
        state.status = "idle";
        state.assetTypes = action.payload;
      });
    builder
      .addCase(getAllAssetType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAllAssetType.fulfilled, (state, action) => {
        state.status = "idle";
        state.allAssetTypes = action.payload;
      });
    builder
      .addCase(addAssetType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addAssetType.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(deleteAssetType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deleteAssetType.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(updateAssetType.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAssetType.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(updateAssetTypeStatus.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateAssetTypeStatus.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(countActiveAssetTypeApi.pending, (state) => {
        state.status = "loading";
      })
      .addCase(countActiveAssetTypeApi.fulfilled, (state, action) => {
        state.status = "idle";
        state.activeAssetTypeCount = action.payload;
      });
    //filter
    builder
      .addCase(getAssetTypeFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getAssetTypeFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      });
  },
});

export const selectAllAssetType = (state) => state.assetType.assetTypes;

export const allAssetTypes = (state) =>
  state.assetType.allAssetTypes.asset_type;

export const assetTypeFieldValueArray = (state) => {
  return state?.assetType?.fieldValues?.users; //for now it is users
};

export default assetTypeSlice.reducer;
