import React from "react";
import { styled } from "@mui/system";
import CrossIcon from "components/Icons/CrossIcon/CrossIcon";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import { ArrowLeftIcon } from "@mui/x-date-pickers";

const PopupHeaderStyles = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "baseline",
  justifyContent: "space-between",
  borderBottom: `1px solid ${theme.palette.gray_250.main}`,
  padding: "0 1.5rem 1.5rem",
  h4: {
    margin: "0",
    color: theme.palette.blackColor.main,
  },
  "&.showBackButton": {
    alignItems: 'center',
    h4: {
      display: 'flex',
      alignItems: 'center',
      gap: "10px",
    }
  }
}));

const ContributePopupHeader = ({
  handlePopupClose,
  popupTitle,
  showBackButton,
  setShowBackButton,
}) => {
  return (
    <PopupHeaderStyles className={showBackButton ? "showBackButton" : ""}>

      <HeadingTag variant={headingVariants.h4}> {showBackButton && (
        <ArrowLeftIcon
          onClick={() => {
            setShowBackButton(false);
          }}
        />
      )} {popupTitle}</HeadingTag>
      <div className="headerClosePopup" onClick={handlePopupClose}>
        <CrossIcon />
      </div>
    </PopupHeaderStyles>
  );
};

export default ContributePopupHeader;
