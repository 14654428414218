import { height, margin, maxHeight, styled } from "@mui/system";

const UploadAssetsBlock = styled("div")(({ theme, disabled = true }) => ({
  ".uploadAssetsBlock": {
    display: "grid",
    gridTemplateColumns: "repeat(2,1fr)",
    gap: "32px",
    maxHeight: "1478px",
    overflow: "auto",
    marginBottom: "32px",
    "@media only screen and (width >= 1920px)": {
      maxHeight: "1630px",
      gap: "32px",
    },

    // minHeight: '1270px',
    "::-webkit-scrollbar-thumb": {
      backgroundColor: theme.palette.whiteColor.main,
    },

    // ".blankUploadSec": {
    //   cursor: "pointer",
    //   "&:first-child": {
    //     gridColumn: "span 2",
    //     ".afterUploadImages": {
    //       ".upladImg": {
    //         img: {
    //           width: "100%",
    //           height: "390px",
    //           objectFit: "cover",
    //           "@media only screen and (width >= 1920px)": {
    //             height: "477px",
    //           },
    //         },
    //       },
    //     },

    //     ".uploadImageBlank": {
    //       minHeight: "347px",
    //       flexDirection: "column",
    //       gap: "16px",
    //       "@media only screen and (width >= 1920px)": {
    //         height: "477px",
    //       },
    //       span: {
    //         display: "block",
    //         color: theme.palette.blackColor.main,
    //       },
    //     },
    //   },
    //   ".uploadImageBlank": {
    //     background: theme.palette.gray_200.main,
    //     display: "flex",
    //     justifyContent: "center",
    //     alignItems: "center",
    //     borderRadius: "8px",
    //     minHeight: "177px",
    //     border: `1px solid ${theme.palette.gray_250.main}`,
    //     "@media only screen and (width >= 1920px)": {
    //       height: "246px",
    //     },
    //   },
    //   ".afterUploadImages": {
    //     position: "relative",
    //     overflow: "hidden",
    //     ".upladImg": {
    //       img: {
    //         width: "100%",
    //         height: "177px",
    //         borderRadius: "8px",
    //         cursor: "pointer",
    //         objectFit: "cover",
    //         objectPosition: "top",
    //         "@media only screen and (width >= 1920px)": {
    //           height: "246px",
    //         },

    //       },
    //     },
    //     ".topBarIcons": {
    //       position: "absolute",
    //       top: "-40px",
    //       display: "flex",
    //       gap: "8px",
    //       left: "0px",
    //       width: "100%",
    //       padding: "0 16px",
    //       transition: "all 0.2s linear",
    //       button: {
    //         backgroundColor: theme.palette.whiteColor.main,
    //         padding: "0",
    //         minWidth: "32px",
    //         border: `1px solid ${theme.palette.gray_250.main}`,
    //         borderRadius: "16px",
    //         svg: {
    //           path: {
    //             stroke: theme.palette.blackColor.main,
    //           },
    //         },
    //         "&:hover": {
    //           backgroundColor: theme.palette.primaryColor.main,
    //           borderColor: theme.palette.primaryColor.main,
    //           svg: {
    //             path: {
    //               stroke: theme.palette.whiteColor.main,
    //             },
    //           },
    //         },
    //         "&.fullScreen": {
    //           margin: "0 0 0 auto",
    //         },
    //       },
    //     },
    //     // ".uploadImageDetailBar": {
    //     //   position: "absolute",
    //     //   bottom: "-50px",
    //     //   width: "100%",
    //     //   padding: "0 16px",
    //     //   transition: "all 0.2s linear",
    //     //   span: {
    //     //     display: "block",
    //     //     color: theme.palette.whiteColor.main,
    //     //     "&.imageId": {
    //     //       opacity: "0.4",
    //     //     },
    //     //   },
    //     // },
    //     "&:hover": {
    //       ".topBarIcons": {
    //         top: "16px",
    //       },
    //       ".uploadImageDetailBar": {
    //         bottom: "24px",
    //       },
    //       ".upladImg": {
    //         cursor: "pointer",
    //         "&:after": {
    //           content: "''",
    //           position: "absolute",
    //           width: "100%",
    //           height: "100%",
    //           top: "0",
    //           left: "0",
    //           background:
    //             "linear-gradient(180deg, rgba(33, 42, 54, 0.00) 0%, #101419 100%)",
    //           WebkitTransition: "all 0.2s linear",
    //           transition: "all 0.2s linear",
    //           borderRadius: "10px",
    //           zIndex: "0",
    //         },
    //       },
    //     },
    //   },

    //   "&:not(first-child)": {
    //     ".uploadImageBlank": {
    //       span: {
    //         display: "none",
    //       },
    //     },
    //   },
    // },
  },

  ".blankUploadSec": {
    cursor: "pointer",
    "&:first-child": {
      gridColumn: "span 2",
      height: '24.097vw',
      overflow: 'hidden',
      borderRadius: '8px',

      "@media only screen and (width >= 1920px)": {

        maxHeight: '477px',
      },
      ".afterUploadImages": {
        ".upladImg": {
          img: {
            width: "100%",
            // height: "347px",
            objectFit: "cover",
          },
        },
      },

      ".uploadImageBlank": {
        flexDirection: "column",
        height: "100%",
        gap: "16px",
        // minHeight: "347px",
        span: {
          display: "block",
          color: theme.palette.blackColor.main,
        },
      },
    },
    ".uploadImageBlank": {
      background: theme.palette.gray_200.main,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "8px",
      minHeight: "177px",
      height: "100%",
    },
    ".afterUploadImages": {
      position: "relative",
      overflow: "hidden",
      height: "100%",
      ".upladImg": {
        height: "100%",
        img: {
          width: "100%",
          // height: "177px",
          borderRadius: "8px",
          cursor: "pointer",
          objectFit: "cover",
          objectPosition: "top",
          height: "100%",
        },
      },
      ".topBarIcons": {
        position: "absolute",
        top: "-40px",
        display: "flex",
        gap: "8px",
        left: "0px",
        width: "100%",
        padding: "0 16px",
        transition: "all 0.2s linear",
      },
      button: {
        backgroundColor: theme.palette.whiteColor.main,
        padding: "0",
        minWidth: "32px",
        border: `1px solid ${theme.palette.gray_250.main}`,
        borderRadius: "16px",
        svg: {
          path: {
            stroke: theme.palette.blackColor.main,
          },
        },
        "&:hover": {
          backgroundColor: theme.palette.primaryColor.main,
          borderColor: theme.palette.primaryColor.main,
          svg: {
            path: {
              stroke: theme.palette.whiteColor.main,
            },
          },
        },
        "&.fullScreen": {
          margin: "0 0 0 auto",
        },
      },
      ".uploadImageDetailBar": {
        position: "absolute",
        bottom: "-100%",
        width: "100%",
        padding: "0 16px",
        transition: "all 0.2s linear",
        display: "flex",
        alignItems: "end",
        span: {
          display: "block",
          color: theme.palette.whiteColor.main,
          "&.imageId": {
            opacity: "0.4",
          },
        },
        ".uploadImageName": {
          flex: "1",
          paddingRight: "16px",
        },
        ".infoIcongBlock": {
          display: "flex",
          alignItems: "center",
          gap: "8px",
          margin: "0 0 0 auto",
        },
      },
      "&:hover": {
        ".topBarIcons": {
          top: "16px",
        },
        ".uploadImageDetailBar": {
          bottom: "16px",
        },
        ".upladImg": {
          cursor: "pointer",
          "&:after": {
            content: "''",
            position: "absolute",
            width: "100%",
            height: "100%",
            top: "0",
            left: "0",
            background:
              "linear-gradient(180deg, rgba(33, 42, 54, 0.00) 0%, #101419 100%)",
            WebkitTransition: "all 0.2s linear",
            transition: "all 0.2s linear",
            borderRadius: "10px",
            zIndex: "0",
          },
        },
      },
    },

    "&:not(:first-child)": {
      height: '12.292vw',
      maxHeight: '246px',
      overflow: 'hidden',
      borderRadius: '8px',
      ".uploadImageBlank": {
        maxHeight: "246px",
        span: {
          display: "none",
        },
      },
    },
  },

  ".seeAllButon": {
    gridColumn: "span 2",
    button: {
      width: "100%",
      maxWidth: "100%",

      "&:hover": {
        backgroundColor: theme.palette.primaryColor.main,
        color: theme.palette.whiteColor.main,
        borderColor: theme.palette.primaryColor.main,
        svg: {
          path: {
            stroke: theme.palette.whiteColor.main,
          },
        },
      },
    },
  },
}));

export default UploadAssetsBlock;
