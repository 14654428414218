import React, { isValidElement, useEffect, useState } from "react";
import NoImageSvg from "components/Icons/NoImageIcon/noImageSvg";
import UploadImageSvg from "components/Icons/UploadImageIcon/uploadImageSvg";
import UploadAssetsBlock from "./DisplayFileStyle";
import TextTag, { textVariants } from "components/Typography/TextTag";
import ButtonWithFrontIcon from "components/Button/ButtonWithfrontIcon";
import HeartIcon from "components/Icons/HeartIcon/HeartIcon";
import CartIcon from "components/Icons/CartIcon/cartIcon";
import Copy2Icon from "components/Icons/CopyIcon/Copy2Icon";
import FullScreenIcon from "components/Icons/FullScreenIcon/FullScreenIcon";
import ThreeDots from "components/Icons/ThreeDots/ThreeDots";
import InfoIcon from "components/Icons/InfoIcon/InfoIcon";
import { useDropzone } from "react-dropzone";
import staticDocImg from "../../../../../Assets/doctemplogo.jpg";
import DownloadIcon from "components/Icons/DownloadIcon/DownloadIcon";
import DownloadStrokeIcon from "components/Icons/DownloadIcon/DownloadStrokeIcon";
import LinkIcon from "components/Icons/LinkIcon/LinkIcon";
const intitalValues = [<UploadImageSvg />, <NoImageSvg />, <NoImageSvg />];
const UploadFile = ({
  uploadAssetsHandler,
  setOpenSidebar,
  contributerData,
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: uploadAssetsHandler,
  });

  const [files, setFiles] = useState([]);
  const [showAllContent, setShowAllContent] = useState(false);
  const [filterFiles, setFilterFiles] = useState([]);
  useEffect(() => {
    const assetsData =
      contributerData?.digital_collections?.[0]?.asset_data || [];
    const assetsLength = assetsData.length;
    if (assetsLength > 0) {
      const temporaryElements = [...intitalValues].slice(assetsLength);
      const assetsServerUrl = `${contributerData?.base_url}/${contributerData?.image_container_name}`;
      const assetsFileSlugUrls = assetsData?.map((asset) => {
        const thumbnailUrl = asset?.file_slug
          ? `${assetsServerUrl}/${asset?.file_slug}`
          : "";
        return {
          id: asset?._id,
          name: asset?.file_name,
          thumbnailUrl: thumbnailUrl,
          assetUrl: `${assetsServerUrl}/${asset?.file_slug}`,
        };
      });

      const concatedData = [...assetsFileSlugUrls, ...temporaryElements];
      setFiles(concatedData);
    } else {
      setFiles(intitalValues);
    }
  }, [
    contributerData,
    contributerData?.base_url,
    contributerData?.image_container_name,
    contributerData?.digital_collections,
  ]);
  useEffect(() => {
    const updatedData = showAllContent ? files : [...files].slice(0, 9);
    setFilterFiles(updatedData);
  }, [files, showAllContent]);

  return (
    <UploadAssetsBlock>
      <div className="uploadAssetsBlock">
        {" "}
        {filterFiles?.map((file, index) => (
          <div className="blankUploadSec" key={index}>
            {isValidElement(file) ? (
              <div
                className="uploadImageBlank"
                {...(index === 0 && getRootProps())}
              >
                {file}
                <input
                  type="file"
                  style={{ display: "none" }}
                  id="uploadMyAssets"
                  {...getInputProps()}
                />
                <TextTag variant={textVariants._14px.semiBold}>
                  <label htmlFor="uploadMyAssets">Upload Assets</label>
                </TextTag>
              </div>
            ) : (
              <div className="afterUploadImages">
                <div className="upladImg">
                  {
                    <img
                      src={
                        file?.thumbnailUrl ? file?.thumbnailUrl : staticDocImg
                      }
                      alt={file?.name}
                    />
                  }
                </div>
                <div className="topBarIcons">
                  <ButtonWithFrontIcon
                    icon={HeartIcon}
                    className="backButtonClass"
                    // startIcon={<KeyboardArrowLeftIcon/>}
                    variant="contained"
                  ></ButtonWithFrontIcon>
                  <ButtonWithFrontIcon
                    icon={CartIcon}
                    className="backButtonClass"
                    // startIcon={<KeyboardArrowLeftIcon/>}
                    variant="contained"
                  ></ButtonWithFrontIcon>
                  <ButtonWithFrontIcon
                    icon={Copy2Icon}
                    className="backButtonClass"
                    // startIcon={<KeyboardArrowLeftIcon/>}
                    variant="contained"
                  ></ButtonWithFrontIcon>
                  <ButtonWithFrontIcon
                    icon={InfoIcon}
                    className="backButtonClass information"
                    // startIcon={<KeyboardArrowLeftIcon/>}
                    variant="contained"
                    onClick={() => setOpenSidebar((open) => (open = !open))}
                  ></ButtonWithFrontIcon>
                  <ButtonWithFrontIcon
                    icon={FullScreenIcon}
                    className="backButtonClass fullScreen"
                    // startIcon={<KeyboardArrowLeftIcon/>}
                    variant="contained"
                  ></ButtonWithFrontIcon>
                </div>
                <div className="uploadImageDetailBar">
                  <div className="uploadImageName">
                    <TextTag
                      variant={textVariants._12px.medium}
                      className="imageId"
                    >
                      {file?.id}
                    </TextTag>
                    <TextTag
                      variant={textVariants._14px.semiBold}
                      className="imageName"
                    >
                      {file?.name}
                    </TextTag>
                  </div>
                  <div className="infoIcongBlock">
                    <ButtonWithFrontIcon
                      icon={DownloadStrokeIcon}
                      className="backButtonClass"
                      // startIcon={<KeyboardArrowLeftIcon/>}
                      variant="contained"
                    ></ButtonWithFrontIcon>
                    <ButtonWithFrontIcon
                      icon={LinkIcon}
                      className="backButtonClass"
                      // startIcon={<KeyboardArrowLeftIcon/>}
                      variant="contained"
                    ></ButtonWithFrontIcon>
                    <ButtonWithFrontIcon
                      icon={Copy2Icon}
                      className="backButtonClass"
                      // startIcon={<KeyboardArrowLeftIcon/>}
                      variant="contained"
                    ></ButtonWithFrontIcon>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      </div>
      {files?.length > 9 && (
        <div className="seeAllButon">
          <ButtonWithFrontIcon
            icon={ThreeDots}
            onClick={() => setShowAllContent(!showAllContent)}
          >
            <TextTag variant={textVariants._12px.medium}>
              {showAllContent ? "Hide" : `See All(${files?.length - 9})`}
            </TextTag>
          </ButtonWithFrontIcon>
        </div>
      )}
    </UploadAssetsBlock>
  );
};
export default UploadFile;
