import React, { useState } from "react";
import withAuth from "Auth/withAuth";
import { routeConfigs } from "utils/routeConfig";
import MainButton, { buttonVariants } from "components/Button/MainButton";
import SearchIcon from "components/Icons/SearchIcon/SearchIcon";
import SearchIconLink from "components/Button/SearchIconLink";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { ArrowLinkComp } from "components/Links/ArrowLinkComp";
import ButtonMiniIcon from "components/Button/ButtonMiniIcon";
import PlainLink from "components/Links/PlainLink";
import DesignSystemStyles from "./designSystemStyles";
import TextfieldWithIcon from "components/Textfield/TextfieldwithIcon";
import PlusMinusPill from "components/Pills/PlusMinusPill";
import CrossIconPill from "components/Pills/CrossIconPill";
import TimeFieldComp from "components/Textfield/TimeFieldComp";

const DesignSystemPage = () => {
  const [plusMinusPill, setPlusMinusPill] = useState(false);

  return (
    <DesignSystemStyles>
      <div className="component-wrapper flexColumn">
        <HeadingTag variant={headingVariants.h1}>Heading H1</HeadingTag>
        <HeadingTag variant={headingVariants.h2}>Heading H2</HeadingTag>
        <HeadingTag variant={headingVariants.h3}>Heading H3</HeadingTag>
        <HeadingTag variant={headingVariants.h4}>Heading H4</HeadingTag>
      </div>
      <div className="component-wrapper flexColumn">
        {/* 18px */}
        <TextTag variant={textVariants._18px.medium}>18px Medium</TextTag>
        {/* 16px */}
        <TextTag variant={textVariants._16px.regular}>16px Regular</TextTag>
        <TextTag variant={textVariants._16px.semiBold}>16px SemiBold</TextTag>
        {/* 14px */}
        <TextTag variant={textVariants._14px.regular}>14px Regular</TextTag>
        <TextTag variant={textVariants._14px.medium}>14px Medium</TextTag>
        <TextTag variant={textVariants._14px.semiBold}>14px SemiBold</TextTag>
        {/* 13px */}
        <TextTag variant={textVariants._13px.regular}>13px Regular</TextTag>
        {/* 12px */}
        <TextTag variant={textVariants._12px.medium}>12px Medium</TextTag>
        <TextTag variant={textVariants._12px.semiBold}>12px SemiBold</TextTag>
        {/* 10px */}
        <TextTag variant={textVariants._10px.medium}>10px Medium</TextTag>
        <TextTag variant={textVariants._10px.semiBold}>10px SemiBold</TextTag>
      </div>
      <div className="component-wrapper">
        <MainButton
          title={"View All Collection"}
          variant={buttonVariants.blue}
        />
        <MainButton
          title={"View All Collection"}
          variant={buttonVariants.blue}
          disabled
        />
      </div>
      <div className="component-wrapper">
        <ButtonMiniIcon>Display search results (49)</ButtonMiniIcon>
        <ButtonMiniIcon disabled>Display search results (49)</ButtonMiniIcon>
      </div>
      <div className="component-wrapper transparent-button">
        <MainButton
          title={"transparent"}
          variant={buttonVariants.transparent}
        />
        <MainButton
          title={"transparent"}
          variant={buttonVariants.transparent}
          disabled
        />
      </div>
      <div className="component-wrapper">
        <MainButton title={"Grey"} variant={buttonVariants.grey} />
        <MainButton title={"Grey"} variant={buttonVariants.grey} disabled />
      </div>
      <div className="component-wrapper transparent-button">
        <SearchIconLink iconComp={<SearchIcon />} title={"School"} />
        <SearchIconLink iconComp={<SearchIcon />} title={"School"} disabled />
      </div>
      <div className="component-wrapper">
        <ArrowLinkComp link={routeConfigs.userHome} title="Photos" />
        <ArrowLinkComp
          link={routeConfigs.adminDashboard}
          title="Photos"
          disabled
        />
      </div>
      <div className="component-wrapper">
        <PlainLink to={routeConfigs.adminDashboard}>Link</PlainLink>
      </div>
      <div className="component-wrapper">
        <TextfieldWithIcon />
      </div>
      <div className="component-wrapper">
        <PlusMinusPill
          isSelected={plusMinusPill}
          onClick={() => {
            setPlusMinusPill((prev) => !prev);
          }}
          name={"Text"}
        />
        <PlusMinusPill
          isSelected={plusMinusPill}
          onClick={() => {
            setPlusMinusPill((prev) => !prev);
          }}
          name={"Text"}
          disabled={true}
        />
      </div>
      <div className="component-wrapper">
        <CrossIconPill name={"Text"} isActive={true} onCrossClick={() => {}} />
        <CrossIconPill
          name={"Text"}
          isActive={true}
          onCrossClick={() => {}}
          disabled={true}
        />
      </div>
      <div className="component-wrapper">
        <TimeFieldComp onChange={(e) => console.log(e)} />
        <TimeFieldComp onChange={(e) => console.log(e)} disabled={true} />
      </div>
    </DesignSystemStyles>
  );
};

export default withAuth(DesignSystemPage, routeConfigs.userLogin);
