import React, { useState } from "react";
import { Stack, styled } from "@mui/system";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { IconButton, useTheme } from "@mui/material";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import CrossIcon from "components/Icons/CrossIcon/CrossIcon";
import CalendarIcon from "components/Icons/CalendarIcon/CalendarIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";
import dayjs from "dayjs";

const DateComponentStyles = styled("div")(({ theme }) => ({
  ".dateSlashtest": {
    fontFamily: "poppins",
    position: "relative",
    border: "1px solid #BDC0C4",
    borderRadius: "8px",
    height: "40px",
    fieldset: {
      display: "none",
    },
    ".MuiFormControl-root": {
      width: "100%",
    },
    ".MuiInputBase-root": {
      padding: "0",

      ".MuiOutlinedInput-input": {
        padding: "11px 16px",
        fontSize: "12px",
        fontWeight: "500",
        fontFamily: "poppins",
      },
    },

    ".firstSlash": {
      position: "absolute",
      left: "34px",
      pointerEvents: "none",
      fontSize: "12px",
      top: "11px",
    },
    ".secondSlash": {
      position: "absolute",
      left: "62px",
      pointerEvents: "none",
      fontSize: "12px",
      top: "11px",
    },
  },
}));

const CustomCalendarHeaderStyles = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "8px 16px",
  alignItems: "center",
  span: {
    color: theme.palette.blackColor.main,
  },
}));

const StyledDateFieldIcons = styled("div")(({ theme }) => ({
  marginRight: "0",
  cursor: "pointer",
  display: "flex",
  gap: "8px",
  position: "absolute",
  right: "16px",
  "&.active": {
    ".calendarIcon": {
      path: {
        stroke: theme.palette.primaryColor.main,
      },
    },
  },
  //   "svg.crossIcon": { stroke: "black" },
}));

const DateComponent = ({ date, onDateChange, disablePast }) => {
  const [open, setOpen] = useState(false);
  const [dateValue, setDateValue] = useState(date);
  const theme = useTheme();

  function CustomCalendarHeader(props) {
    const { currentMonth, onMonthChange } = props;

    const selectNextMonth = () =>
      onMonthChange(currentMonth.add(1, "month"), "left");
    const selectNextYear = () =>
      onMonthChange(currentMonth.add(1, "year"), "left");
    const selectPreviousMonth = () =>
      onMonthChange(currentMonth.subtract(1, "month"), "right");
    const selectPreviousYear = () =>
      onMonthChange(currentMonth.subtract(1, "year"), "right");

    return (
      <CustomCalendarHeaderStyles>
        <Stack spacing={1} direction="row">
          <IconButton onClick={selectPreviousMonth} title="Previous month">
            <ChevronLeft />
          </IconButton>
          <IconButton onClick={selectPreviousYear} title="Previous year">
            <KeyboardDoubleArrowLeftIcon />
          </IconButton>
        </Stack>
        <TextTag variant={textVariants._14px.medium}>
          {currentMonth.format("MMM YYYY")}
        </TextTag>
        <Stack spacing={1} direction="row">
          <IconButton onClick={selectNextYear} title="Next year">
            <KeyboardDoubleArrowRightIcon />
          </IconButton>
          <IconButton onClick={selectNextMonth} title="Next month">
            <ChevronRight />
          </IconButton>
        </Stack>
      </CustomCalendarHeaderStyles>
    );
  }

  return (
    <DateComponentStyles>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <div className="dateSlashtest">
          <DatePicker
            open={open}
            onClose={() => setOpen(false)}
            onChange={(e) => {
              setDateValue(e?.$d);
              onDateChange(e);
            }}
            format={dateValue ? "DD/MM/YYYY" : "DD  MM  YYYY"}
            minDate={disablePast ? dayjs() : null} // Disable past dates
            value={dateValue ? dayjs(dateValue) : dayjs(date)}
            showDaysOutsideCurrentMonth
            slotProps={{
              textField: {
                placeholder: "DD  MM  YYYY",
                readOnly: true,
                onClick: () => {
                  setOpen(true);
                },
                InputProps: {
                  endAdornment: (
                    <StyledDateFieldIcons className={open ? "active" : ""}>
                      {dateValue && (
                        <CrossIcon
                          className={"crossIcon"}
                          onClick={(e) => {
                            e.preventDefault();
                            setOpen(false);
                            setDateValue(null);
                            onDateChange();
                          }}
                        />
                      )}
                      <CalendarIcon className="calendarIcon" />
                    </StyledDateFieldIcons>
                  ),
                },
              },
              openPickerIcon: {
                onClick: () => {
                  setOpen(true);
                },
              },
              popper: {
                sx: {
                  ".MuiPaper-root": {
                    borderRadius: "10px",
                  },
                  button: {
                    borderRadius: "8px",
                    "&.MuiPickersDay-dayOutsideMonth": {
                      color: theme.palette.gray_300.main,
                    },
                    "&.MuiPickersDay-root": {
                      "&:not(.MuiPickersDay-dayOutsideMonth)": {
                        "&.Mui-selected": {
                          background: theme.palette.primaryColor.main,
                        },
                      },
                    },
                    "&.MuiPickersDay-today": { border: "none" },
                  },
                },
              },
            }}
            slots={{
              openPickerIcon: CalendarIcon,
              calendarHeader: CustomCalendarHeader,
            }}
          />
          {!dateValue && (
            <>
              <span className="firstSlash">/</span>
              <span className="secondSlash">/</span>
            </>
          )}
        </div>
      </LocalizationProvider>
    </DateComponentStyles>
  );
};

export default DateComponent;
