import { useCallback, useEffect, useRef, useState } from "react";
import { Grid, Box } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  allDeprtMentsWithFilterData,
  departmentfieldValueArray,
} from "./features/depatmentSlice";
import {
  addDepartment,
  deleteDepartment,
  getActiveDeartment,
  getAllDepartments,
  getDepartmentFields,
  getDepartments,
  updateDepartment,
  updateDepartmentStatus,
} from "./features/departmentAction";
import PageTitle from "../AdminComponents/PageTitle/PageTitle";
import ToastHelper from "../../../helper/toastHelper";
import {
  validationMessages,
  validationRegex,
} from "utils/validation/validationUtils";
import CustomComponentFilter, {
  ShowHeaderIcon,
  getFieldsDataHelper,
} from "pages/Admin-Pages/AdminComponents/CustomComponentfilter/CustomComponentFilter";
import ToggleSwitch from "pages/Admin-Pages/AdminComponents/ToggleSwitch/ToggleSwitch";
import { getBusiness } from "../sub-groups/features/subGroupAction";
import { selectAllBusiness } from "../sub-groups/features/subGroupSlice";
import EditTertiaryGroupPopup from "./components/editTertiaryGroupPopup";
import TertiaryGroupHead from "./components/tertiaryGroupHead";
import TabsComponent from "pages/Admin-Pages/AdminComponents/Tabs/Tabs";
import { groupsTabs } from "pages/Admin-Pages/AdminComponents/Tabs/TabsConstant";
import CommonGrid from "components/Grid/CommonGrid";
import {
  AgViewColumn,
  commonRowDataKeys,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import { AgCellEditor } from "utils/datatable/AgCellEditor";
import { agSetColumnIndexes } from "pages/Admin-Pages/AdminComponents/ColumnDrag/ColumnDrag";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";

import { FieldManagementStyle } from "../fields-management/fieldManagementStyle";
import TableEditIcon from "components/Grid/TableComponents/TableIcons/TableEditIcon";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import { useTranslation } from "react-i18next";
import CommonDeletePopup from "../AdminComponents/CommonDeletePopup/CommonDeletePopup";

export default function Departments() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const departmentData1 = useSelector(allDeprtMentsWithFilterData);
  const businessData = useSelector(selectAllBusiness);
  const gridRef = useRef();

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    hiddenAgGridColumn("business"),
    {
      field: "selectionCheckbox",
      headerName: "",
      checkboxSelection: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxColumn",
      maxWidth: "64",
      suppressNavigable: true,
      lockPosition: "left",
    },
    {
      field: "name",
      headerName: t("tertiary"),
      headerClass: "leftAlign",
      minWidth: 250,
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "name",
          dispatchName: "name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"name"}
                sortBy={"name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellEditor: AgCellEditor,
      cellEditorParams: {
        checkAlphaNumericValidation: true,
        editFunction: editDepartment,
      },
      cellClass: "editableCell",
      editable: true,
    },
    {
      field: "business_data",
      headerName: t("secondary"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "business_data.name",
          dispatchName: "business_data.name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"business_data.name"}
                sortBy={"business_data.name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
    },
    {
      field: "regions_data",
      headerName: t("primary"),
      headerClass: "leftAlign",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "regions_data.name",
          dispatchName: "regions_data.name",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"regions_data.name"}
                sortBy={"regions_data.name"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
    },
    {
      field: "enabled",
      headerName: t("status"),
      headerClass: "fieldCenter",
      headerComponent: (params) => {
        const filterClickProps = {
          dispatchGetName: "enabled",
          dispatchName: "enabled",
          applied_filters: params?.applied_filters_data?.applied_filters,
        };
        return (
          <th
            key={params?.column?.colId}
            className="userRole leftAlign"
            onClick={(e) => {
              commonFilterOnClick(e, filterClickProps);
            }}
            id={params?.column?.colId}
          >
            {params?.displayName}
            <button>
              <ShowHeaderIcon
                columnName={"enabled"}
                sortBy={"enabled"}
                dataState={params?.applied_filters_data?.dataState}
              />
            </button>
          </th>
        );
      },
      headerComponentParams: {
        applied_filters_data: [],
      },
      cellClass: "statusColumn",
      cellRenderer: (props) => {
        return (
          <Grid className="toggleBlockCo">
            <ToggleSwitch
              checked={props?.value}
              value={props?.value}
              defaultChecked={props?.value}
              onChange={(e) => {
                gridRef.current.api.deselectAll();
                setCurrentRowData(props?.data);
                handleSwitchChange(
                  props?.data?._id, //id
                  props?.value, //value,
                  props,
                );
              }}
            />
          </Grid>
        );
      },
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <th>{props?.displayName}</th>,
      cellRenderer: (props) => (
        <Grid container className="actionIcons">
          <TableEditIcon
            onClick={() => {
              gridRef.current.api.deselectAll();
              setEditOpen(true);
              setCurrentRowData(props?.data);
            }}
          />
          <TableDeleteIcon
            onClick={() => {
              setRowsToBeDeleted([props?.data?._id]);
              dispatch(getActiveDeartment()).then((response) => {
                if (
                  response?.payload?.total_department <= 1 &&
                  props?.data?.enabled
                ) {
                  //if only one record is enabled and the record we are deleting is also enabled
                  //means we are deleting last enabled record then show toast
                  ToastHelper(
                    "error",
                    validationMessages.oneTertiaryGroupShouldBeEnabled,
                  );
                } else {
                  setOpenDelete(true);
                }
              });
            }}
          />
        </Grid>
      ),
    },
  ];

  const [rowData, setRowData] = useState();
  const [colDefs, setColDefs] = useState(initialColDefs);
  const fieldValueData = useSelector(departmentfieldValueArray); //filter
  const [departmentName, setDepartmentName] = useState("");
  const [currentRowData, setCurrentRowData] = useState("");
  const [alphaNumErrorAdd, setAlphaNumErrorAdd] = useState("");
  const [previousColumnFilterData, setPreviousColumnFilterData] = useState(); //filter
  const [selectedRows, setSelectedRows] = useState();
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedValue, setSelectedValue] = useState(" ");
  const [editOpen, setEditOpen] = useState(false);
  const [settingType, setSettingType] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [dataState, setDataState] = useState({
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "desc", //probable values: asc, desc
    sortBy: "createdAt", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  }); //filter changes

  const [openPopOver, setOpenPopOver] = useState({
    open: false,
    data: [],
    keys: "",
    dispatchName: "",
  }); //filter

  useEffect(() => {
    setIsLoading(true);
  }, []);

  useEffect(() => {
    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) => (commonDataObj[commonKey] = departmentData1?.[commonKey]),
    );
    setRowData(
      departmentData1?.departments?.map((tableDataObj) => ({
        _id: tableDataObj?._id,
        business: tableDataObj?.business,
        name: tableDataObj?.name,
        business_data:
          tableDataObj?.business_data?.[0]?.name ||
          tableDataObj?.business_data?.name,
        regions_data:
          tableDataObj?.regions_data?.[0]?.name ||
          tableDataObj?.regions_data?.name,
        enabled: tableDataObj?.enabled,
        action: tableDataObj?.action,
        other_table_data: commonDataObj,
      })),
    );
  }, [departmentData1]);

  useEffect(() => {
    dispatch(getBusiness());
  }, [dispatch]);

  useEffect(() => {
    // setIsLoading(true);
    dispatch(getDepartments(dataState)).then((res) => {
      const paramsObj = {
        tableDataResponse: res, //response form get table data api
        setSettingType, //state to set setting type which will be requied in set column settings api
        colDefs, //state which gives current column defination
        setColDefs, //state to set column definations
        dataState,
      };
      agSetColumnIndexes(paramsObj);
      setIsLoading(false);
    });
    //eslint-disable-next-line
  }, [dispatch, dataState]);

  useEffect(() => {
    setPreviousColumnFilterData(departmentData1?.applied_filters);
  }, [departmentData1]);

  const handleSwitchChange = useCallback(
    async (
      entryId, //id
      switchValue, //value true or false
      props,
    ) => {
      gridRef.current.api.forEachNode((rowNode) => {
        if (rowNode?.data?._id !== entryId) {
          return false;
        }

        resetErrorStates();
        setSelectedRows([]);
        setRowsToBeDeleted([]);

        //updating the ui
        //by toggling switchvalue
        rowNode.setDataValue("enabled", !switchValue);

        let modifiedDataState = dataState;

        if (props?.data?.other_table_data) {
          const other_data = props?.data?.other_table_data;
          modifiedDataState = modifyDataState(other_data);
        }

        const dataBody = {
          body: {
            departmentId: entryId,
            enabled: !switchValue,
          },
          dataState: modifiedDataState,
        };

        //runnign the actual update api
        dispatch(updateDepartmentStatus(dataBody)).then((resp) => {
          if (resp?.error) {
            //reverting if error occurs
            dispatch(getDepartments(modifiedDataState));
            return "error";
          }
        });
      });
    },
    //eslint-disable-next-line
    [],
  );

  const resetErrorStates = () => {
    setAlphaNumErrorAdd("");
  };

  const settingNewDepartment = (e) => {
    const value = e.target.value;
    const alphaNumericRegex = validationRegex.alphanumeric;
    if (!validationRegex?.checkForDoubleSpaces.test(e.target.value)) {
      setAlphaNumErrorAdd(validationMessages.noConsecutiveDoubleSpaces);
    } else if (e.target.value.length <= 50 && alphaNumericRegex.test(value)) {
      resetErrorStates();
      setDepartmentName(e.target.value);
    } else if (!alphaNumericRegex.test(value)) {
      setAlphaNumErrorAdd(validationMessages.alphanumericOnly);
    }
  };

  function handleEditPopClose() {
    setEditOpen(false);
  }

  function editDepartment(data) {
    const body = {
      departmentId: data?.dataObj?._id || data?.departmentId,
      newDepartment: data?.updatedValue || data?.newDepartment,
      enabled: data?.dataObj?.enabled || data?.enabled ? true : false,
      business: data?.dataObj?.business || data?.business,
    };

    let modifiedDataState = dataState;

    if (data?.dataObj?.other_table_data) {
      const other_data = data?.dataObj?.other_table_data;
      modifiedDataState = modifyDataState(other_data);
    }
    const dataBody = { body, dataState: modifiedDataState };
    dispatch(updateDepartment(dataBody)).then((res) => {
      dispatch(getDepartments(modifiedDataState));
      if (res?.error) {
        return;
      }
      handleEditPopClose();
    });
  }

  function addNewDepartment() {
    const trimmedAsset = !!departmentName && departmentName.trim();
    if (!selectedValue || selectedValue?.trim() === "") {
      ToastHelper("error", validationMessages.pleaseSelectBusinessUnit);
      return;
    }
    if (!trimmedAsset || trimmedAsset === "") {
      ToastHelper("error", validationMessages.pleaseEnterTertiaryGroup);
      return;
    }
    const body = { name: trimmedAsset, business: selectedValue };
    dispatch(addDepartment(body)).then((res) => {
      if (res?.error) {
        return;
      }
      setDataState({
        ...dataState,
        search: "",
        limit: 20,
        page: 1,
        sortOrder: "desc",
        sortBy: "createdAt",
        column_name: "",
        column_values: "",
      });
      resetErrorStates();
      setDepartmentName("");
      setSelectedValue(" ");
    });
    return;
  }

  const handleDelete = () => {
    const body = {
      ids: rowsToBeDeleted,
    };
    const dataBody = { body, dataState };

    const shouldPageChange = deletePageChangeChecker(
      departmentData1?.currentPage,
      departmentData1?.totalDocuments,
      rowsToBeDeleted?.length,
      departmentData1?.limit,
    );

    dataBody.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };

    dispatch(deleteDepartment(dataBody)).then((res) => {
      if (res.error) {
        return;
      }
      setOpenDelete(false);
      setSelectedRows([]);
      if (shouldPageChange) setDataState(dataBody.dataState);
    });
  };

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  function dispatchGetSelectedData(data) {
    setDataState(data);
  }

  function commonFilterOnClick(e, props) {
    const { dispatchGetName, dispatchName, applied_filters } = props;
    const ref = e.currentTarget;
    getFieldsDataHelper(
      applied_filters,
      dispatchGetName,
      dispatch,
      getDepartmentFields,
    );
    setOpenPopOver({
      ...openPopOver,
      open: ref,
      dispatchName,
      keys: "",
    });
  }

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
  };

  const extraBulkDeleteProps = {
    //props required for extra bulk delete functionality
    //if allowBulkDeleteConditions is false then no need to send other props at all
    allowBulkDeleteConditions: true,
    //if true bulk delete will not check for extra conditions
    childCountExtension: null,
    setOpenedFor: false, //nothing to reassign
    getActiveEntries: getActiveDeartment,
    activeTableEntriesExtension: "total_department",
    lastRecordValidationMessage:
      validationMessages.oneTertiaryGroupShouldBeEnabled,
    getAllEntries: getAllDepartments,
    allEntriesExtension: "departments",
    setOpen: null,
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("organization")} />
      <TabsComponent tabsDataArray={groupsTabs} />
      <Box container spacing={4} className={"userReq-table"}>
        <Grid container spacing={4} className="newTableBox">
          <Grid item xs={12}>
            <Box className="fieldTableMain">
              <Box className="fieldTableHeader">
                <TertiaryGroupHead
                  selectedValue={selectedValue}
                  setSelectedValue={setSelectedValue}
                  businessData={businessData}
                  setDataState={setDataState}
                  settingNewDepartment={settingNewDepartment}
                  addNewDepartment={addNewDepartment}
                  departmentName={departmentName}
                  alphaNumErrorAdd={alphaNumErrorAdd}
                  dataState={dataState}
                  setSelectedRows={setSelectedRows}
                />
                <AgViewColumn
                  colDefs={colDefs}
                  setColDefs={setColDefs}
                  gridRef={gridRef}
                  dispatch={dispatch}
                  settingType={settingType}
                  dataState={dataState}
                />
              </Box>
              <CommonGrid
                commonGridObj={commonGridObj}
                selectedRows={selectedRows}
                setOpenDelete={setOpenDelete}
                setRowsToBeDeleted={setRowsToBeDeleted}
                setSelectedRows={setSelectedRows}
                isLoading={isLoading}
                totalPages={departmentData1?.totalPages}
                handlePageChange={handlePageChange}
                currentPage={dataState?.page}
                {...extraBulkDeleteProps}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>
      {openPopOver && (
        <CustomComponentFilter
          openPopOver={openPopOver}
          setOpenPopOver={setOpenPopOver}
          setDataState={setDataState}
          dataState={dataState}
          setPreviousColumnFilterData={setPreviousColumnFilterData}
          previousColumnFilterData={previousColumnFilterData}
          dispatchGetSelectedData={dispatchGetSelectedData}
          fieldValueData={fieldValueData}
        />
      )}
      <CommonDeletePopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        setRowsToBeDeleted={setRowsToBeDeleted}
        handleDelete={handleDelete}
        multiDeleteText={t("tertiaryDeleteMutipleText")}
        singleDeleteText={t("tertiaryDeleteSingleText")}
      />
      <EditTertiaryGroupPopup
        editOpen={editOpen}
        handleEditPopClose={handleEditPopClose}
        businessData={businessData}
        currentRowData={currentRowData}
        editDepartment={editDepartment}
      />
    </FieldManagementStyle>
  );
}
