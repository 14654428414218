import { styled } from "@mui/system";

const MapLocationStyle = styled("div")(({ theme }) => ({
  ".noLocationFound ": {
    color: theme.palette.gray_400.main,
  },
  // margin: 30,
  display: "grid",
  gridGap: 10,
  "&.addOpacity": {
    opacity: "0.24",
    pointerEvents: "none",
  },
  ".editMap-head": {
    display: "flex",
    placeItems: "center",
    gap: 10,
    button: {
      display: "flex",
      placeItems: "center",
      gap: 4,
      width: 80,
      fontWeight: "500",
      height: "30px",
      border: "none",
      placeContent: "space-evenly",
      borderRadius: "10px",
      color: theme.palette.whiteColor.main,
      backgroundColor: "rgb(0, 86, 184)",
    },
  },
  ".editMap-body": {
    "> span": {
      marginBottom: "16px",
      display: "block",
      color: theme.palette.blackColor.main,
    },
    ".editMap-map": {
      width: "100%",
      height: "297px",
      maxWidth: "100%",
      borderRadius: "8px",
      overflow: "hidden",
    },
  },
  ".hideTags": {
    ".gmnoprint , .gmnoscreen , .gm-style-cc , a[target=noopener] , img[alt=Google]  ":
    {
      display: "none",
    },
  },
  ".saveLocation-head": {
    ".mapTextField": {
      display: "flex",
      position: "relative",
      svg: {
        "&.crossIcon": {
          position: "absolute",
          right: "16px",
          top: "12px",
          cursor: "pointer",
        },
        path: {
          stroke: theme.palette.blackColor.main,
        },
      },
      "> div": {
        width: "100%",
      },
      input: {
        paddingRight: "32px",
        fontFamily: "Poppins",
        fontSize: "12px",
        fontStyle: "normal",
        fontWeight: "500",
        lineHeight: "normal",
        letterSpacing: "-0.12px",
      },
    },
  },
  ".saveLocation-body": {
    ".saveLocation-map": {
      height: "400px",
      width: "100%",
      borderRadius: "10px",
      overflow: "hidden",
    },
  },
  ".saveLocation-foot": {
    display: "flex",
    width: "100%",
    "button:first-child": {
      flex: 1,
      backgroundColor: theme.palette.lightGray.main,
      color: theme.palette.primary.main,
      borderColor: theme.palette.dimGray.main,
    },
    "button:last-child": {
      flex: 5,
      backgroundColor: theme.palette.blue_Color.main,
      color: theme.palette.white.main,
    },
  },
}));

export default MapLocationStyle;
