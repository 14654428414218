import { createAsyncThunk } from "@reduxjs/toolkit";
import performApiRequest from "helper/apiRequestHelper";
import { apiRoutes } from "utils/apiRoutes";
import axiosConfig from "utils/axiosConfig";

export const getContributionbyCollectionId = createAsyncThunk(
  "collection/getContributionbyCollectionId",
  async (paramData) => {
    try {
      let apiUrl;
      if (paramData?.collectionId && (!paramData?.limit || !paramData?.page)) {
        apiUrl = `${apiRoutes.adminGetContributionbyCollectionId}/${paramData?.collectionId}?sortOrder=asc&sortBy=order_index&limit=999999`;
      } else {
        const {
          sortBy,
          sortOrder,
          limit,
          page,
          filtersQueryString,
          collectionId,
        } = paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.adminGetContributionbyCollectionId}/${collectionId}?sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getAllContributionbyCollectionId = createAsyncThunk(
  "collection/getAllContributionbyCollectionId",
  async (paramData) => {
    try {
      const apiUrl = `${apiRoutes.adminGetContributionbyCollectionId}/${paramData?.collectionId}?sortOrder=asc&sortBy=order_index&limit=999999`;

      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const Getcollection = createAsyncThunk(
  "collection/collections",
  async (paramData = null) => {
    try {
      let apiUrl;
      if (paramData === null) {
        apiUrl = `${apiRoutes.assetsGetcollection}?limit=5000&data_limit=all`;
      } else {
        const { sortBy, sortOrder, limit, page, filtersQueryString } =
          paramData;
        const encodedQueryString = encodeURIComponent(
          JSON.stringify(filtersQueryString),
        );
        apiUrl = `${apiRoutes.assetsGetcollection}?data_limit=all&sortBy=${sortBy}&sortOrder=${sortOrder}&limit=${limit}&page=${page}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const getCollectionUser = createAsyncThunk(
  "collection/getCollectionUser",
  async () => {
    try {
      const apiUrl = `${apiRoutes.assetsGetcollection}?limit=5000&data_limit=all`;

      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateCollections = createAsyncThunk(
  "collection/updateCollection",
  async (collectionedit, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.assetsUpdateCollection}${collectionedit.body.id}`,
          collectionedit.body,
        ),
      );
      return dispatch(Getcollection(collectionedit?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const addcollection = createAsyncThunk(
  "collections/addcollection",
  async (newcollection, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.post(`${apiRoutes.assetsPostcollection}`, newcollection),
      );
      return dispatch(Getcollection());
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deletecollection = createAsyncThunk(
  "collections/deletecollection",
  async (data, { dispatch }) => {
    try {
      await performApiRequest(() =>
        axiosConfig.put(apiRoutes.assetsDeletecollection, data?.body),
      );
      return dispatch(Getcollection(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//filter API's
export const getCollectionFields = createAsyncThunk(
  "collections/getCollectionFields",
  async (data) => {
    try {
      const { column, filters } = data;
      let apiUrl = `${apiRoutes.adminGetCollectionFields}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetCollectionFields}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const deleteContributeCollection = createAsyncThunk(
  "collections/deleteContributeCollection",
  async (data) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(apiRoutes.adminDeleteContributeCollection, data.body),
      );
      // return dispatch(getContributionbyCollectionId(data?.dataState));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

export const updateContributeCollection = createAsyncThunk(
  "collections/updateContributeCollection",
  async (updatedAssetType) => {
    try {
      return await performApiRequest(() =>
        axiosConfig.put(
          `${apiRoutes.adminUpdateContributeCollection}/${updatedAssetType.body.id}`,
          updatedAssetType.body,
        ),
      );
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);

//adminGetUploadedCollectionFilterFields
//filter API's
export const getUploadedCollectionFilterFields = createAsyncThunk(
  "collections/getUploadedCollectionFilterFields",
  async (data) => {
    try {
      const { column, filters, id } = data;
      let apiUrl = `${apiRoutes.adminGetUploadedCollectionFilterFields}/${id}?column=${column}`;
      if (column && filters) {
        const encodedQueryString = encodeURIComponent(JSON.stringify(filters));
        apiUrl = `${apiRoutes.adminGetUploadedCollectionFilterFields}/${id}?column=${column}&filters=${encodedQueryString}`;
      }
      return await performApiRequest(() => axiosConfig.get(apiUrl));
    } catch (error) {
      return { success: false, error: error.response.data };
    }
  },
);
