import React, { useState } from "react";
import { t } from "i18next";
import _ from "lodash";
import ButtonWithFrontIcon from "components/Button/ButtonWithfrontIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";
// import UploadAssetIcon from "components/Icons/UploadIcon/UploadAssetIcon";
import ThreeDots from "components/Icons/ThreeDots/ThreeDots";
import PlusIcon from "components/Icons/PlusIcon/PlusIcon";
import FileIcon from "components/Icons/FileIcon/FileIcon";
import HeartIcon from "components/Icons/HeartIcon/HeartIcon";
import DetailFormLeftHeader from "../DetailForm/components/DetailFormLeftHeader";
import { activeCompObj } from "../../Contribute";
import DescriptionStyle from "./DescriptionStyle";
import { scrollToElementId } from "utils/scrollToElementId";
// import ImageIconProfileSetting from "components/Icons/ImageIcon/ImageIconSetting";
import SimilarCollection from "components/Icons/SimilarCollection/SimilarCollection";

const Description = ({
  contributerData,
  uploadAssetsHandler,
  unMountActiveComponent,
  activeComponent,
  mountDetailSection,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const mainTitleObj = contributerData?.[0]?.fieldsData.filter(
    (a) => a.main_title && a.visible,
  )?.[0];

  const descObj = contributerData?.[0]?.fieldsData.filter(
    (a) => a.main_description && a.visible,
  )?.[0];

  const clientIdObj = contributerData?.[0]?.fieldsData.filter(
    (a) => a.client_id && a.visible,
  )?.[0];

  console.log(clientIdObj);

  const desc = descObj?.field_value?.value;
  const client_id = clientIdObj?.field_value?.value;

  const handleEditDetails = (name) => {
    mountDetailSection();
    //setTimeout 0 makes it such that belo code is ran after the details section is opened
    // setTimeout(() => {
    //   scrollToElementId(name);
    // }, 0);
  };

  return (
    <DescriptionStyle>
      <div>
        <div className="detailsInfoBlock">
          <div
            className={`titleBlock ${activeComponent && activeComponent !== activeCompObj.detailForm
                ? "addOpacity"
                : ""
              }`}
          >
            {mainTitleObj?.field_value?.value && (
              <DetailFormLeftHeader
                title={mainTitleObj?.field_value?.value}
                // onEditClick={() => handleEditDetails(mainTitleObj?.name)}
                activeComponent={activeComponent === activeCompObj.detailForm}
                onCloseClick={unMountActiveComponent}
                mainTitle={true}
              />
            )}
          </div>
          <div className="assetsButtonsBlock">
            <ButtonWithFrontIcon icon={SimilarCollection} disabled>
              <TextTag variant={textVariants._14px.semiBold}>
                {t("similarCollection")}
              </TextTag>
            </ButtonWithFrontIcon>
            {/* <ButtonWithFrontIcon
              icon={UploadAssetIcon}
              onClick={(e) => {
                e.stopPropagation();
                unMountActiveComponent();
              }}
            >
              <input
                type="file"
                id="uploadAssetImages"
                onChange={(e) => uploadAssetsHandler(e?.target?.files)}
                style={{ display: "none" }}
              />
              <TextTag variant={textVariants._12px.medium}>
                <label htmlFor="uploadAssetImages"> {t("uploadAssets")}</label>
              </TextTag>
            </ButtonWithFrontIcon> */}
            {/* <ButtonWithFrontIcon icon={ThreeDots} onClick={() => {}} disabled>
              <TextTag variant={textVariants._12px.medium}> Lorem</TextTag>
            </ButtonWithFrontIcon> */}
            <ButtonWithFrontIcon icon={PlusIcon} onClick={() => { }} disabled />
            <ButtonWithFrontIcon icon={FileIcon} onClick={() => { }} disabled />
            <ButtonWithFrontIcon icon={HeartIcon} onClick={() => { }} disabled />
          </div>
        </div>
        <DetailFormLeftHeader
          title={t("details")}
          onEditClick={() => {
            handleEditDetails(descObj?.name);
          }}
          activeComponent={activeComponent === activeCompObj.detailForm}
          onCloseClick={unMountActiveComponent}
        />

        <div
          className={`descriptionBlock ${activeComponent && activeComponent !== activeCompObj.detailForm
              ? "addOpacity"
              : ""
            }`}
        >
          {clientIdObj && !_.isEmpty(clientIdObj) && (
            <div className="showCaseDetailSection">
              <span className="nameClass">{clientIdObj?.name}</span>
              {client_id && (
                <div className="descSection">
                  <TextTag variant={textVariants._16px.regular}>
                    {client_id}
                  </TextTag>
                </div>
              )}
            </div>
          )}
          {descObj && !_.isEmpty(descObj) && (
            <div className="showCaseDetailSection">
              <span className="nameClass">{descObj?.name}</span>
              {desc && (
                <div className="descSection">
                  <TextTag variant={textVariants._16px.regular}>
                    {isExpanded ? (
                      desc.split("\n").map((line, index) => (
                        <span key={index} className="line">
                          {line}
                        </span>
                      ))
                    ) : (
                      <span className="line">
                        {desc.length > 434
                          ? desc
                            ?.slice(0, 434)
                            ?.split("\n")
                            ?.map((line, index) => (
                              <span key={index} className="line">
                                {line}
                              </span>
                            ))
                          : desc.split("\n").map((line, index) => (
                            <span key={index} className="line">
                              {line}
                            </span>
                          ))}
                      </span>
                    )}
                  </TextTag>

                  {desc.length > 434 && !isExpanded && (
                    <TextTag
                      variant={textVariants._14px.medium}
                      onClick={toggleExpand}
                      className="readAllBtn"
                    >
                      {t("Read all")}
                    </TextTag>
                  )}

                  {isExpanded && (
                    <TextTag
                      variant={textVariants._14px.medium}
                      onClick={toggleExpand}
                      className="readAllBtn"
                    >
                      {t("hide")}
                    </TextTag>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </DescriptionStyle>
  );
};

export default Description;
