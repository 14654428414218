import React from "react";
import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import CrossIcon from "components/Icons/CrossIcon/CrossIcon";
import TextTag, { textVariants } from "components/Typography/TextTag";

const StyledLi = styled("li")(({ theme }) => ({
  border: `1px solid ${theme.palette.gray_300.main}`,
  padding: "7px 16px",
  borderRadius: "30px",
  display: "flex",
  flexWrap: "wrap",
  gap: "8px",
  cursor: "pointer",
  color: theme.palette.gray_300.main,
  span: {
    color: theme.palette.blackColor.main,
  },
  "&.selectedKeyWordsTags": {
    span: {
      lineHeight: "normal",
      color: theme.palette.blackColor.main,
    },
    button: {
      padding: "0",
      height: "16px",
      backgroundColor: "transparent",
      svg: {
        path: {
          stroke: theme.palette.gray_400.main,
        },
      },
    },
  },
  "&:hover": {
    border: `1px solid ${theme.palette.primaryColor.main}`,
    button: {
      svg: {
        path: {
          stroke: theme.palette.primaryColor.main,
        },
      },
    },
  },
  "&.disabled": {
    pointerEvents: "none",
    cursor: "auto",
    borderColor: theme.palette.gray_250.main,
    backgroundColor: theme.palette.gray_250.main,
    span: {
      color: theme.palette.gray_500.main,
    },
    button: {
      svg: {
        path: {
          stroke: theme.palette.gray_400.main,
        },
      },
    },
  },
}));

const CrossIconPill = ({ name, isActive, onCrossClick, disabled }) => {
  return (
    <StyledLi className={`selectedKeyWordsTags ${disabled ? "disabled" : ""}`}>
      <TextTag variant={textVariants._12px.medium}>{name}</TextTag>
      {isActive && (
        <IconButton onClick={onCrossClick}>
          <CrossIcon />
        </IconButton>
      )}
    </StyledLi>
  );
};

export default CrossIconPill;
