import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Grid, Checkbox, Box } from "@mui/material";
import PageTitle from "pages/Admin-Pages/AdminComponents/PageTitle/PageTitle";
import { Getcollection } from "../collections/features/collectionAction";
import { routeConfigs } from "utils/routeConfig";
import TabsComponent from "pages/Admin-Pages/AdminComponents/Tabs/Tabs";
import { useQuery } from "utils/queryString";
import {
  adminDeleteFieldsApi,
  adminUpdateFieldRowsIndexApi,
  getFieldsDataByCollectionId,
  updateFieldPermission,
} from "./features/fieldsManagementAction";
import { fieldManagementState } from "./features/fieldsManagementSlice";
import PaginationLimitDropDown from "pages/Admin-Pages/AdminComponents/PaginationDropdown/PaginationLimitDropDown";
import CustomButton from "components/Button/CustomButton";
import AddNewFieldPopup from "./Components/AddNewFieldPopup";
import DeleteConfirmationPopup from "./Components/DeleteConfirmationPopup";
import { deletePageChangeChecker } from "helper/gridDeleteHelper";
import {
  AgViewColumn,
  commonRowDataKeys,
  commonTooltipHeaderParams,
  hiddenAgGridColumn,
  modifyDataState,
} from "utils/datatable/agTableOptions";
import { agSetColumnIndexes } from "pages/Admin-Pages/AdminComponents/ColumnDrag/ColumnDrag";
import CommonGrid from "components/Grid/CommonGrid";
import { CommonEditTooltip } from "components/TooltipComponent/CommonEditTooltip";
import { assetCollection } from "pages/Admin-Pages/AdminComponents/Tabs/TabsConstant";
import TableEditIcon from "components/Grid/TableComponents/TableIcons/TableEditIcon";
import TableDeleteIcon from "components/Grid/TableComponents/TableIcons/TableDeleteIcon";
import { FieldManagementStyle } from "./fieldManagementStyle";

const FieldsManagement = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const gridRef = useRef();
  const [searchParams] = useSearchParams();
  const currentCollectionId1 = useQuery().get("id");

  const fieldsAllDataByCollectionId = useSelector(fieldManagementState);
  const tableDataArray =
    fieldsAllDataByCollectionId?.fieldsByCollection?.fields;

  const initialDataState = {
    search: "", //table search value
    limit: 20, //number of records to show per page
    page: 1, //current page number
    sortOrder: "asc", //probable values: asc, desc
    sortBy: "order_index", //column_name through which sort is applied
    column_name: "", //column name through which filter is applied
    column_values: "", //selected column data values in filter
    latest_action: "", //probable values: sort, filter and empty
    filtersQueryString: [], //array of selected filters [{}];
  };

  const openPopupForObj = {
    add: "Add",
    edit: "EDIT",
  };

  const [dataState, setDataState] = useState(initialDataState); //filter changes

  const agHeaderComponentRender = (data) => {
    return (
      <div key={data?.displayName} id={data?.displayName}>
        <div className="withIcon">
          {data?.displayName}
          <CommonEditTooltip
            tooltip_data={data?.tooltip_data}
            displayName={data?.displayName}
            field_name={data?.column?.colId}
            dataState={data?.dataState}
            setDataState={setDataState}
          />
        </div>
      </div>
    );
  };

  const agCellRendererCheckbox = (props, mainTitle = false) => (
    <div className="center">
      <Checkbox
        defaultChecked={props?.value}
        onChange={(e) => {
          gridRef.current?.api.deselectAll();
          handletableCheckbox(
            e.target.checked,
            props?.column?.colId,
            props?.data?._id,
            props,
            mainTitle,
          );
        }}
      />
    </div>
  );

  const initialColDefs = [
    hiddenAgGridColumn("_id"),
    hiddenAgGridColumn("field_type_id"),
    hiddenAgGridColumn("field_default_value"),
    hiddenAgGridColumn("description"),
    hiddenAgGridColumn("custom_tag_id"),
    {
      field: "rowDrag",
      headerName: "",
      checkboxSelection: true,
      rowDrag: true,
      headerCheckboxSelection: true,
      suppressFiltersToolPanel: true,
      headerClass: "checkboxDragColumn",
      maxWidth: "110",
      suppressNavigable: true,
      lockPosition: "left",
      foreverShow: true,
    },
    {
      field: "name",
      headerName: t("name"),
      headerClass: "leftAlign",
      cellClass: (props) => {
        if (props?.data?.main_description) {
          return "mainDescription";
        }
        if (props?.data?.main_title) {
          return "mainTitle";
        }
        if (props?.data?.client_id) {
          return "clientId";
        }
        return "";
      },
    },
    {
      field: "field_type",
      headerName: t("type"),
      cellClass: "fieldFirstLetterCapital",
    },
    {
      field: "searchable",
      headerName: t("searchble"),
      cellRenderer: agCellRendererCheckbox,
      headerComponent: agHeaderComponentRender,
      headerClass: "fieldCenter",
      cellClass: "category_center",
      headerComponentParams: commonTooltipHeaderParams,
    },
    {
      field: "visible",
      headerName: t("visible"),
      cellRenderer: agCellRendererCheckbox,
      headerComponent: agHeaderComponentRender,
      headerClass: "fieldCenter",
      cellClass: "category_center",
      headerComponentParams: commonTooltipHeaderParams,
    },
    {
      field: "required_enabled",
      headerName: t("requiredNotRequired"),
      minWidth: 285,
      cellRenderer: agCellRendererCheckbox,
      headerComponent: agHeaderComponentRender,
      headerClass: "fieldCenter",
      cellClass: "category_center",
      headerComponentParams: commonTooltipHeaderParams,
    },
    {
      field: "filter",
      headerName: t("filter"),
      minWidth: 285,
      cellRenderer: agCellRendererCheckbox,
      headerComponent: agHeaderComponentRender,
      headerClass: "fieldCenter",
      cellClass: "category_center",
      headerComponentParams: commonTooltipHeaderParams,
    },
    {
      field: "action",
      headerName: t("action"),
      headerClass: "fieldCenter",
      cellClass: "actionColumn",
      headerComponent: (props) => <span>{props?.displayName}</span>,
      cellRenderer: (props) => (
        <Grid container className="actionIcons">
          <TableEditIcon
            onClick={() => {
              gridRef.current?.api.deselectAll();
              setCurrentRowData(props.data);
              setOpenNewFieldPopupFor(openPopupForObj.edit);
              setOpenAddNewFieldPopup(true);
            }}
          />
          <TableDeleteIcon
            onClick={() => {
              gridRef.current?.api.deselectAll();
              setCurrentRowData(props.data);
              setOpenDelete(true);
            }}
          />
        </Grid>
      ),
    },
  ];

  const [tabsDataArray, setTabsDataArray] = useState();
  const [openAddNewFieldPopup, setOpenAddNewFieldPopup] = useState(false);
  const [currentRowData, setCurrentRowData] = useState();
  const [openNewFieldPopupFor, setOpenNewFieldPopupFor] = useState(); //ADD or EDIT
  const [openDelete, setOpenDelete] = useState(false);
  const [rowsToBeDeleted, setRowsToBeDeleted] = useState([]);
  const [rowData, setRowData] = useState(tableDataArray);
  const [colDefs, setColDefs] = useState(initialColDefs);
  const [currentCollectionId, setCurrentCollectionId] = useState();
  const [isloading, setIsLoading] = useState(false);
  const [settingType, setSettingType] = useState("");
  const [selectedRows, setSelectedRows] = useState(false);

  useEffect(() => {
    try {
      setIsLoading(true);
      setCurrentCollectionId(atob(currentCollectionId1));
      setDataState(initialDataState);
    } catch (e) {}
    //eslint-disable-next-line
  }, [currentCollectionId1]);

  useEffect(() => {
    const commonDataObj = {};
    commonRowDataKeys?.map(
      (commonKey) =>
        (commonDataObj[commonKey] =
          fieldsAllDataByCollectionId?.fieldsByCollection?.[commonKey]),
    );

    setRowData(
      tableDataArray?.map((fieldData) => ({
        _id: fieldData?._id,
        field_type_id: fieldData?.field_type_id,
        name: fieldData?.name,
        field_type: fieldData?.field_type?.name,
        searchable: fieldData?.searchable,
        visible: fieldData?.visible,
        required_enabled: fieldData?.required_enabled,
        action: "",
        field_default_value: fieldData?.field_default_value,
        description: fieldData?.description,
        custom_tag_id: fieldData?.custom_tag_id,
        main_title: fieldData?.main_title,
        main_description: fieldData?.main_description,
        client_id: fieldData?.client_id,
        filter: fieldData?.filter,
        fieldLimit: fieldData?.field_default_value?.[0]?.limit,
        other_table_data: commonDataObj,
      })),
    );
    setIsLoading(false);
  }, [tableDataArray, fieldsAllDataByCollectionId?.fieldsByCollection]);

  useEffect(() => {
    dispatch(Getcollection()).then((resp) => {
      if (resp?.error) {
        return;
      }
      const dataArray = [];
      resp?.payload?.collections?.map((dataObj) => {
        if (dataObj?.enabled) {
          dataArray.push({
            _id: dataObj?._id,
            value: routeConfigs.adminFieldsManagement,
            label: dataObj?.name,
          });
        }
        return false;
      });
      if (!window.location.search) {
        //set first tab when page opens for the first time
        navigate({
          pathname: window.location.pathname,
          search: `?id=${btoa(dataArray?.[0]?._id)}`,
          replace: true,
        });
      }
      setTabsDataArray(dataArray);
    });
    //eslint-disable-next-line
  }, [dispatch, searchParams]);

  useEffect(() => {
    if (currentCollectionId?.length > 20) {
      //to verify if collection id is actually an id and not any other thing
      dispatch(
        getFieldsDataByCollectionId({
          collectionId: currentCollectionId,
          ...dataState,
        }),
      ).then((res) => {
        setRowsToBeDeleted(false);
        setSelectedRows(false);
        const paramsObj = {
          tableDataResponse: res, //response form get table data api
          setSettingType, //state to set setting type which will be requied in set column settings api
          colDefs, //state which gives current column defination
          setColDefs, //state to set column definations
          dataState,
        };
        agSetColumnIndexes(paramsObj);
      });
    }
    //eslint-disable-next-line
  }, [currentCollectionId, dispatch, dataState]);

  const onRowDragEnd = useCallback(
    (e) => {
      const dataBody = {
        body: {
          old_index:
            parseInt(e.node.id) +
            1 +
            (dataState.limit * dataState.page - dataState.limit),
          new_index:
            parseInt(e.node.rowIndex) +
            1 +
            (dataState.limit * dataState.page - dataState.limit),
          collection_id: currentCollectionId,
        },
        dataState,
      };
      dispatch(adminUpdateFieldRowsIndexApi(dataBody));
    },
    [currentCollectionId, dataState, dispatch],
  );

  const handlePageChange = (e, value) => {
    setDataState({ ...dataState, page: value });
  };

  const handletableCheckbox = (val, key, id, props, main_title = false) => {
    const body = {
      [key]: val,
      id,
    };
    const bodyNew = {
      body,
      currentCollectionId,
      dataState,
    };

    dispatch(updateFieldPermission(bodyNew)).then((res) => {
      if (res?.error) {
        return;
      }
      if (main_title && typeof main_title === "boolean") {
        let modifiedDataState = dataState;

        if (props?.data?.other_table_data) {
          const other_data = props?.data?.other_table_data;
          modifiedDataState = modifyDataState(other_data);
        }

        dispatch(
          getFieldsDataByCollectionId({
            collectionId: atob(currentCollectionId1),
            ...modifiedDataState,
          }),
        );
      }
    });
  };

  const handleDelete = (deleteIdArray) => {
    const data = {
      dataState,
      collectionId: currentCollectionId,
      body: { ids: deleteIdArray || rowsToBeDeleted },
    };

    const shouldPageChange = deletePageChangeChecker(
      fieldsAllDataByCollectionId?.fieldsByCollection?.currentPage,
      fieldsAllDataByCollectionId?.fieldsByCollection?.totalDocuments,
      rowsToBeDeleted?.length,
      fieldsAllDataByCollectionId?.fieldsByCollection?.limit,
    );

    data.dataState = {
      ...dataState,
      page: shouldPageChange ? dataState?.page - 1 : dataState?.page,
    };

    dispatch(adminDeleteFieldsApi(data)).then((resp) => {
      if (resp?.error) {
        return;
      }
      setOpenDelete(false);
      setRowsToBeDeleted([]);
      setSelectedRows([]);
      setDataState(data.dataState);
      //further code to run after delete
    });
  };

  const icons = useMemo(() => {
    return {
      rowDrag: `<img src ="./logos/menu.svg")} style="height: 46px; width: 26px;padding-right: 2px"/>`,
    };
  }, []);

  const commonGridObj = {
    //put additional grid props and options here
    ref: gridRef,
    rowData,
    columnDefs: colDefs,
    onRowDragEnd,
    rowDragManaged: true,
    icons,
  };

  return (
    <FieldManagementStyle>
      <PageTitle title={t("administration")} />
      <TabsComponent tabsDataArray={assetCollection} />
      {tabsDataArray?.length > 0 ? (
        window.location.search && (
          <TabsComponent
            tabsDataArray={tabsDataArray}
            setDataState={setDataState}
            initialDataState={initialDataState}
            className="subTabs"
          />
        )
      ) : (
        <Box style={{ height: "120px" }} />
      )}
      <Box container spacing={4} className={"userReq-table  withSubTab"}>
        <Box item xs={12}>
          <Box className="fieldTableMain">
            <Box className="fieldTableHeader">
              <Box className="table-upper customeBar_ assestType collectionAssets paddingZero">
                <Box className="leftSideInputs">
                  <Grid container spacing={4} className={"addNew"}>
                    <Box className={"addNewDiv1"}>
                      <CustomButton
                        className="mr-0"
                        onClick={() => {
                          setOpenNewFieldPopupFor(openPopupForObj.add);
                          setCurrentRowData([]);
                          setOpenAddNewFieldPopup(true);
                        }}
                        variant="outlined"
                      >
                        {t("addNewField")}
                      </CustomButton>
                    </Box>
                  </Grid>
                  <PaginationLimitDropDown
                    dataState={dataState}
                    setDataState={setDataState}
                    setSelectedRows={null} //no rows to select
                  />
                </Box>
              </Box>
              <AgViewColumn
                colDefs={colDefs}
                setColDefs={setColDefs}
                gridRef={gridRef}
                dispatch={dispatch}
                settingType={settingType}
                dataState={dataState}
              />
            </Box>
            <CommonGrid
              commonGridObj={commonGridObj}
              selectedRows={selectedRows}
              setOpenDelete={setOpenDelete}
              setRowsToBeDeleted={setRowsToBeDeleted}
              setSelectedRows={setSelectedRows}
              isLoading={
                fieldsAllDataByCollectionId?.state === "loading" || isloading
              }
              totalPages={
                fieldsAllDataByCollectionId?.fieldsByCollection?.totalPages
              }
              handlePageChange={handlePageChange}
              currentPage={dataState?.page}
              minNumberOfColumnsForAutosize={8}
            />
          </Box>
        </Box>
      </Box>
      <DeleteConfirmationPopup
        openDelete={openDelete}
        setOpenDelete={setOpenDelete}
        rowsToBeDeleted={rowsToBeDeleted}
        handleDelete={handleDelete}
        setRowsToBeDeleted={setRowsToBeDeleted}
        gridRef={gridRef}
        currentRowData={currentRowData}
      />
      {openAddNewFieldPopup && (
        <AddNewFieldPopup
          openAddNewFieldPopup={openAddNewFieldPopup}
          setOpenAddNewFieldPopup={setOpenAddNewFieldPopup}
          openNewFieldPopupFor={openNewFieldPopupFor}
          collectionId={currentCollectionId}
          currentRowData={currentRowData}
          openPopupForObj={openPopupForObj}
          dataState={dataState}
          setDataState={setDataState}
        />
      )}
    </FieldManagementStyle>
  );
};

export default FieldsManagement;
