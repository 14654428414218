import { display, margin, styled } from "@mui/system";

export const SidebarStyles = styled("div")(({ theme }) => ({
  ".dashboardData": {
    // marginTop: "40px",
    padding: "15px",
  },
  ".sideBarDashBoard": {},
  ".sideBarClosed ul div .active": { background: "#f6f7ff" },
  ".hoverOpenClose": {
    // position: "fixed",
    // left: "0",
    // zIndex: "1",
    background: theme.palette.white.main,
    height: "25px",
    width: "25px",
    transform: " translateX(33px)",
    // transform: "translate(336px, 85px)",
    boxShadow: "0 0 0 #000, 0 0 5px #c5c5c5",
    transition: "transform 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
    // display: "none",
    svg: {
      width: "14px",
    },
    "&:hover": {
      backgroundColor: theme.palette.primary.main,
      svg: {
        fill: theme.palette.white.main,
        width: "14px",
      },
    },
  },
  ".sideBarClosed": {
    ".MuiDrawer-root > .MuiDrawer-paperAnchorLeft": {
      paddingBottom: "86px",
      paddingTop: "86px",
      "&:hover": {
        ".hoverOpenClose": {
          display: "flex",
        },
      },
    },
    ".hoverOpenClose": {
      // transform: "translate(74px, 85px)",
      // display: "flex",
    },
    main: { maxWidth: "calc(100% - 100px)" },
    ".footerLanguageChange.adminLanguageChange ": {
      paddingLeft: "15px",
      " .MuiFormControl-root ": {
        "svg.languageDownIcon": {
          display: "none",
        },
        " .MuiSelect-select ": {
          paddingLeft: "14px",
          svg: {
            flex: "0 0 28px",
          },
          "span.css-i0utto": {
            display: "none",
          },
        },
      },
    },
    ".singleLink": {
      overflow: "hidden",
      ".InnerText": {
        display: "none",
      },
    },
  },

  ".sidebarOpened": {
    ".MuiDrawer-root": { zIndex: 1 },
    overflow: "hidden",
    ".MuiDrawer-root > .MuiDrawer-paperAnchorLeft": {
      paddingBottom: "86px",
      paddingTop: "86px",
      "&:hover": {
        ".hoverOpenClose": {
          display: "flex",
        },
      },
    },
  },
  ".singleLink": {
    // marginTop: "120px",
    overflow: "auto",
    "::-webkit-scrollbar": {
      width: "5px",
      height: "0",
    },
    "> a.link": {
      ":hover": {
        background: theme.palette.lightBlue.main,
      },
    },
    "> div": {
      "> .link:hover": {
        background: theme.palette.lightBlue.main,
      },
    },
    li: {
      ".linkIcon": {
        justifyContent: "center",
        "&.active": {
          svg: {
            fill: "#041E42",
          },
        },
      },
      ".InnerText": {
        paddingLeft: "18px;",
      },
      " &.active": {
        background: theme.palette.lightBlue.main,
      },
    },
  },
  a: {
    color: theme.palette.black.main,
    ".linkIcon": {
      justifyContent: "center",
      svg: {
        width: "30px",
        height: "30px",
      },
    },
    ".InnerText": {
      paddingLeft: "18px",
      "@media screen and (max-width:1366px)": {
        span: {
          fontSize: "14px",
        },
      },
    },
    ":is(.active)": {
      background: theme.palette.lightBlue.main,
      svg: {
        fill: theme.palette.primary.main,
        width: "30px",
        height: "30px",
      },
    },
    ":not(.active)": {
      color: theme.palette.dimGray.main,
    },
    ".linkText": {
      svg: {
        width: "28px",
        height: "28px",
      },
    },
  },
  ".dropDownBlock": {
    ".linkIcon": {
      minWidth: "30px",
      svg: {
        width: "28px",
        height: "28px",
      },
    },
    ".link": {
      padding: "4px 15px 4px 80px",
      ".InnerText": {
        paddingLeft: "0",
        margin: "0",
      },
    },
  },
  ".sidebarLanguage": {
    margin: "auto 0 0 0",
    textAlign: "center",
    ".adminLanguageChange ": {
      textAlign: "left",
      paddingLeft: "36px",
    },
  },
}));

export default SidebarStyles;
