import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { Box, Tooltip, Pagination, IconButton } from "@mui/material";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import RestoreIcon from "@mui/icons-material/Restore";
import { Loader } from "components/Loader/Loader";
import {
  agGridDefaultConfig,
  defaultColDef,
  fitCellContentFieldsIdsArr,
} from "utils/datatable/agTableOptions";
import ToastHelper from "helper/toastHelper";
import PaginationStyle from "components/Pagination/style";
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-material.css"; // Optional Theme applied to the grid
import { routeConfigs } from "utils/routeConfig";
import { CommonGridStyle } from "./commonGridStyle";
import { useTranslation } from "react-i18next";

export const autoSizeGridRef = (gridRef) => {
  setTimeout(() => {
    gridRef?.current?.api?.autoSizeColumns(fitCellContentFieldsIdsArr, false);
  }, 100);
};

const CommonGrid = (props) => {
  const { t } = useTranslation();
  const {
    commonGridObj,
    setRowsToBeDeleted,
    setSelectedRows,
    isLoading,
    totalPages,
    handlePageChange,
    currentPage,
    setOpenDelete,
    rowSelection, //optional
    rowHeight, //optional
    setOpenRecover, //only required on recover page
    minNumberOfColumnsForAutosize,
    //following are only required if need to apply conditions on bulk delete
    allowBulkDeleteConditions,
    childCountExtension,
    setOpenedFor,
    getActiveEntries,
    activeTableEntriesExtension,
    lastRecordValidationMessage,
    getAllEntries,
    allEntriesExtension,
    setOpen,
  } = props;

  /**
   * commonGridObj: this contains all the grid props that we are directly passing ahead without changing
   * setRowsToBeDeleted: Kind of simillar to selectedRows with Arrays of Ids of selected rows.
   * setSelectedRows: State that will set the selectedRows
   * isLoading: To show the loader instead of grid
   * totalPages: total number of pages grid have
   * handlePageChange: function to handle page change
   * currentPage: current page number
   * setOpenDelete: <multidelete> state that will open the delete popup
   * rowSelection: <optional> what kind of row selection do you want single or multiple
   * setOpenDelete: <multidelete> state that will open the delete popup
   * minNumberOfColumnsForAutosize:<optional>set minimum number of column after which column autosize will work default 6
   * ----------------------------------------------------------------------------------------------------------------
   * allowBulkDeleteConditions:<multidelete-condition> to check if bulk delete conditions are needed to be applied
   * childCountExtension:<multidelete-conditiion> extension to be put after dot to get number of child entries
   * setOpenedFor: <multidelete-condition> state to set why popup is opened.
   * getActiveEntries: <multidelete-condition> dispatch api to get active entries.
   * activeTableEntriesExtension: <multidelete-condition> After dispatching activeTableEntriesNumber api,
   *                              extension to be put after response.payload in .then.
   * lastRecordValidationMessage: <multidelete-condition> validation message for last active record.
   * getAllEntries: <multidelete-condition> dispatch api to get all entries
   * allEntriesExtension: <multidelete-condition> After dispatching getAllEntriesData api,
   *                      extension to be put after response.payload in .then.
   * setOpen: <multidelete-condition> state to set wether reassign popup opens or not
   */

  const dispatch = useDispatch();
  const [selectedRowsData, setSelectedRowsData] = useState();
  const columnLengthSize = minNumberOfColumnsForAutosize
    ? minNumberOfColumnsForAutosize
    : 6;

  useEffect(() => {
    const showingColumns = commonGridObj?.columnDefs?.filter(
      (col) => !col?.hide,
    );
    //only using autsize if column length is greator than
    if (showingColumns?.length >= columnLengthSize) autoSizeAll(false);
    //eslint-disable-next-line
  }, [commonGridObj?.columnDefs]);

  const handleSelectionChanged = (props) => {
    //runs when rows are selected or deselectd in the grid
    //this function extracts ids from the selected rows
    //and puts them in an array

    const selectedRowsData = props.api.getSelectedRows();

    const selectedRowsIds =
      selectedRowsData?.length > 0 &&
      selectedRowsData.map((objData) => objData._id);

    setSelectedRowsData(selectedRowsData);
    setRowsToBeDeleted &&
      setRowsToBeDeleted(selectedRowsIds ? selectedRowsIds : []);
    setSelectedRows && setSelectedRows(selectedRowsIds ? selectedRowsIds : []);
  };

  const AgMultiDeleteHeader = () => {
    //shows on top of grid when rows are selected
    const onBulkDeleteClick = () => {
      let allEntriesAreDisabled = true;
      let noOfChildEntries = 0;

      selectedRowsData?.map((rowData) => {
        if (rowData?.enabled) {
          //if only selected entry is active
          allEntriesAreDisabled = false;
        }
        //total number of child entries
        noOfChildEntries += rowData?.[childCountExtension];
        return false;
      });

      if (setOpenedFor) setOpenedFor("DELETE");

      dispatch(getActiveEntries()).then((res) => {
        if (
          res?.payload?.[activeTableEntriesExtension] <= 1 &&
          !allEntriesAreDisabled
        ) {
          //if only one record is enabled
          //and the one of the record we are deleting is also enabled
          //means we are deleting last enabled record then show toast
          commonGridObj.ref.current.api.deselectAll();
          ToastHelper("error", lastRecordValidationMessage);
        } else {
          dispatch(getAllEntries()).then((resp) => {
            //checking if after deleting all the entries
            //we have atleast one enabled entry left
            const filteredEntries = resp?.payload?.[
              allEntriesExtension
            ]?.filter(
              (entriesData) =>
                !selectedRowsData?.some(
                  (selectedObj) => selectedObj?._id === entriesData?._id,
                ) && entriesData?.enabled,
            );

            const filteredMainArrayLength = filteredEntries?.length;

            if (filteredMainArrayLength > 0) {
              if (noOfChildEntries > 0) {
                //if child entries exist open reassign popup
                setOpen(true);
              } else {
                //else open delete confirmation popup
                setOpenDelete(true);
              }
            } else {
              commonGridObj.ref.current.api.deselectAll();
              ToastHelper("error", lastRecordValidationMessage);
            }
          });
        }
      });
    };

    return (
      <Box
        className={`fieldMultiSelectHeader ${
          selectedRowsData?.length > 0 ? "" : "hideFieldMultiDelete"
        }`}
      >
        {Array.isArray(selectedRowsData) && selectedRowsData?.length > 0 && (
          <>
            <Tooltip placement="bottom" title={t("delete")}>
              <IconButton
                onClick={() =>
                  allowBulkDeleteConditions
                    ? onBulkDeleteClick()
                    : setOpenDelete(true)
                }
              >
                <DeleteForeverIcon />
              </IconButton>
            </Tooltip>
            {window.location.pathname === routeConfigs.adminUserRecover && (
              <Tooltip placement="bottom" title={t("recover")}>
                <IconButton
                  onClick={() => setOpenRecover && setOpenRecover(true)}
                >
                  <RestoreIcon />
                </IconButton>
              </Tooltip>
            )}
            <span>
              {selectedRowsData?.length} {t("rowsSelected")}
            </span>
          </>
        )}
      </Box>
    );
  };

  const customFooterFunc = (count, handlePageChange, page) => {
    const CountInt = count ? parseInt(count) : 0;
    //when count not available send 0
    const PageInt = parseInt(page);
    return (
      <tfoot>
        <tr>
          <td>
            <PaginationStyle>
              <Pagination
                className="paginationComp"
                count={CountInt}
                onChange={handlePageChange}
                page={PageInt}
              />
            </PaginationStyle>
          </td>
        </tr>
      </tfoot>
    );
  };

  const autoSizeAll = (skipHeader) => {
    // following commented code is to dynamically select all column headings for resize
    // const allColumnIds = [];
    // commonGridObj.ref?.current?.api?.getColumns()?.forEach((column) => {
    //   allColumnIds.push(column.getId());
    // });
    setTimeout(() => {
      commonGridObj?.ref?.current?.api?.autoSizeColumns(
        fitCellContentFieldsIdsArr,
        // allColumnIds,
        skipHeader,
      );
    }, 10);
  };

  const autoSizeStrategy = useMemo(() => {
    const showingColumns = commonGridObj?.columnDefs?.filter(
      (col) => !col?.hide,
    );

    if (showingColumns?.length >= columnLengthSize)
      return {
        type: "fitCellContents",
        skipHeader: false,
        colIds: fitCellContentFieldsIdsArr,
      };
    //eslint-disable-next-line
  }, [commonGridObj?.columnDefs]);

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <CommonGridStyle>
        <Box
          className="ag-theme-material" // applying the ag grid theme
        >
          <AgMultiDeleteHeader selectedRowsData={selectedRowsData} {...props} />
          <AgGridReact
            {...agGridDefaultConfig}
            {...commonGridObj}
            className={`customfieldGrid ${
              commonGridObj?.rowData?.length > 0 ? "" : "noRowsGrid"
            }`}
            rowSelection={rowSelection || "multiple"}
            defaultColDef={defaultColDef}
            rowHeight={rowHeight || 50}
            onSelectionChanged={handleSelectionChanged}
            autoSizeStrategy={autoSizeStrategy}
          />
        </Box>
        {totalPages && handlePageChange && currentPage ? (
          <Box className="fieldTableFooter">
            {customFooterFunc(totalPages, handlePageChange, currentPage)}
          </Box>
        ) : null}
      </CommonGridStyle>
    </>
  );
};

export default CommonGrid;
