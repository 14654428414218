import React from "react";
import { Box } from "@mui/system";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import TextTag, { textVariants } from "components/Typography/TextTag";
import MainButton, { buttonVariants } from "components/Button/MainButton";
import videoSrc from "./stocksVideos.mp4";
import StockVideoStyles from "./StockVideoStyles";
import { t } from "i18next";

const StockVideo = () => {
  const stockVideoData = {
    stockVideoSectionHeading: t("stockVideoSectionHeading"),
    stockVideoSectionDesc: t("stockVideoSectionDesc"),
  };
  return (
    <StockVideoStyles>
      <Box className="stocksVideoBlock">
        <Box className="videoBlock">
          <video autoPlay loop muted>
            <source src={videoSrc} type="video/mp4" />
          </video>
          <Box className="stocksVideosText">
            <HeadingTag variant={headingVariants.h1}>
              {stockVideoData.stockVideoSectionHeading}
            </HeadingTag>
            <TextTag variant={textVariants._16px.regular}>
              {stockVideoData.stockVideoSectionDesc}
            </TextTag>
            <MainButton
              title={"View All Collection"}
              variant={buttonVariants.transparent}
            />
          </Box>
        </Box>
      </Box>
    </StockVideoStyles>
  );
};

export default StockVideo;
