import React, { useRef, useState } from "react";
import FeaturedProjectStyles from "./featuredProjectStyles";
import { register } from "swiper/element/bundle";
import image1 from "./Images/featuredImageOne.jpg";
import image2 from "./Images/featuredImageTwo.jpg";
import image3 from "./Images/featuredImageThree.jpg";
import image4 from "./Images/featuredImagefour.jpg";
import image5 from "./Images/featuredImagefive.jpg";
import image6 from "./Images/featuredImageSix.jpg";
import HomeSliderTop from "../HomeSliderTop";
import HeadingTag, { headingVariants } from "components/Typography/HeadingTag";
import TextTag, { textVariants } from "components/Typography/TextTag";
import { useTranslation } from "react-i18next";

register();

const FeaturedProjects = () => {
  const { t } = useTranslation();

  const sectionHeading = t("featuredProjects");
  const swiperElRef = useRef(null);

  const featuredProjectsData = [
    {
      project_image: image1,
      project_name: "University of Texas Hall of Fame",
      project_description:
        "The UT Hall of Fame project consisted of renovating the existing north end zone food court of Darrel K Royal stadium and converting it into a world-class hall of fame that will memorialize and celebrate the University of Texas' past, present, and future athletic achievements",
      project_uid: "#121582312",
    },
    {
      project_image: image2,
      project_name: "Lower.com Field",
      project_description:
        "The 460,000 square foot stadium is home to the Columbus Crew SC. Built on a 12-acre site and anchoring the west end of the Arena District, the stadium occupies 20,000-seats and a natural grass pitch",
      project_uid: "#121589735",
    },
    {
      project_image: image3,
      project_name: "Momentum BMW",
      project_description:
        "Sonic Automotive relocated the Momentum BMW dealership to the former location of a Sears department store on Southwest Freeway, following this adaptive-reuse renovation of the facility. The 180,000 square foot renovation of the old department store building was carried out in several phases",
      project_uid: "#121589785",
    },
    {
      project_image: image4,
      project_name: "Lincoln Center, David Geffen Hall",
      project_description:
        "Turner is providing construction management services for the renovation and revitalization of David Geffen Hall, a part of New York City's Lincoln Center for Performing Arts campus in Manhattan. The concert hall is home to the New York Philharmonic and hosts a variety of other public and private events",
      project_uid: "#129982312",
    },
    {
      project_image: image5,
      project_name: "609 H Street - Bond Collective Fitout",
      project_description:
        "The new Bond Collective coworking space is a luxury shared office workspace that offers traditional offices ranging for 1-10 people, in addition to scalable setups for teams of 10-50+ with custom floor plates, growth schedules and swing spaces",
      project_uid: "#121586935",
    },
    {
      project_image: image6,
      project_name: "McCarthy Tetrault LLP",
      project_description:
        "The new office space revolutionized the firm's work culture with its modern layout. The top client-facing floor included 12 client meetings rooms including video-conferencing rooms, presentation rooms, on-line meeting rooms and one divisible training/conference room inclusive of projection capabilities, blackout blinds and an operable wall",
      project_uid: "#121510785",
    },
  ];

  const FeaturedProjectSlideComp = (props) => {
    const [hoverClass, setHoverClass] = useState();
    const { imgData } = props;

    return (
      <div
        className={`featured_slide_inner 
      `}
      >
        <img
          className="featuredSlideImage"
          src={imgData?.project_image}
          alt={`swiper pic`}
          onMouseOver={() => setHoverClass(true)}
          onMouseLeave={() => setHoverClass(false)}
        />
        <div className="slideHoverTop">
          <TextTag variant={textVariants._13px.regular}>
            {imgData?.project_uid}
          </TextTag>
        </div>
        <div className="hoverSlide">
          <div className="slideHoverBottom">
            <HeadingTag variant={headingVariants.h3}>
              {imgData?.project_name}
            </HeadingTag>
            <TextTag variant={textVariants._13px.regular}>
              {imgData?.project_description}
            </TextTag>
          </div>
        </div>
      </div>
    );
  };

  return (
    <FeaturedProjectStyles>
      <div className="featured_main">
        <HomeSliderTop
          sectionHeading={sectionHeading}
          swiperElRef={swiperElRef}
        />
        <div className="featured_slider">
          <swiper-container
            ref={swiperElRef}
            slides-per-view="3.139"
            slides-offset-before="0"
            slides-offset-after="64"
            space-between="32"
          >
            {featuredProjectsData?.map((imgData, i) => (
              <swiper-slide key={i}>
                <FeaturedProjectSlideComp imgData={imgData} />
              </swiper-slide>
            ))}
          </swiper-container>
        </div>
      </div>
    </FeaturedProjectStyles>
  );
};

export default FeaturedProjects;
