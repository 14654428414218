import React, { useEffect, useState } from "react";
import { Close, Search } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  ClickAwayListener,
  DialogActions,
  FormControlLabel,
  FormGroup,
  Popper,
  TextField,
} from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined"; //filterIcon
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp"; //filter up arrow
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown"; //filter down arrow
import CustomButton from "components/Button/CustomButton";
import filterImg from "./images/Icon awesome-sort-alpha-up-alt.svg";
import filterImgA from "./images/Icon awesome-sort-alpha-up.svg";
import { CheckBoxContainer } from "./CustomComponentStyle";
import i18n from "Assets/i18n/config";

const t = i18n.t;

export function getFieldsDataHelper(
  previousColumnFilterData, //data from previous columns
  dispatchName, //current column name to dispatch
  dispatch, //useDispatch's dispatch
  dispatchAction, //action in dispatch
  id = "", //only in case of custom tags data
) {
  //to get the fields data when click on column heading
  const prevArray = Array.isArray(previousColumnFilterData)
    ? [...previousColumnFilterData]
    : [];

  const modifiedValues = [...prevArray];
  const getFieldsObj = { column: dispatchName, id };
  if (modifiedValues.length > 0) {
    getFieldsObj.filters = modifiedValues;
  }
  dispatch(dispatchAction(getFieldsObj));
}

export function ShowHeaderIcon(props) {
  //this element returns the icons that will be shown on the table header
  const { columnName, sortBy, dataState } = props;

  //checking if columnName exist in appliedFiltersArray
  let columnExist = false;
  dataState?.filtersQueryString?.map((filterObj) => {
    if (filterObj?.column_name === columnName) {
      columnExist = true;
    }
    return false;
  });

  if (columnExist && dataState?.sortBy === sortBy) {
    //when both filter and sort are happening on the same column
    if (dataState?.latest_action === "sort") {
      if (dataState.sortOrder === "asc") {
        return <ArrowDropUpIcon className="upDownSortIcon" />;
      } else if (dataState.sortOrder === "desc") {
        return <ArrowDropDownIcon className="upDownSortIcon" />;
      }
    }
    if (dataState?.latest_action === "filter") {
      return <FilterAltIcon />;
    }
  } else if (columnExist) {
    //when filter is applied on the single column
    return <FilterAltIcon />;
  } else if (dataState?.sortBy === sortBy) {
    //when sort is applied on single column
    if (dataState.sortOrder === "asc") {
      return <ArrowDropUpIcon className="upDownSortIcon" />;
    } else if (dataState.sortOrder === "desc") {
      return <ArrowDropDownIcon className="upDownSortIcon" />;
    }
  } else {
    return <FilterAltOutlinedIcon />;
  }
}

const CustomComponentFilter = (props) => {
  const {
    openPopOver,
    setOpenPopOver,
    setDataState,
    dataState,
    setPreviousColumnFilterData,
    previousColumnFilterData,
    fieldValueData,
  } = props;

  const id = openPopOver?.open?.innerText;
  const [filterText, setFilterText] = useState("");
  const [showData, setShowData] = useState([]);
  const [allChecked, setAllChecked] = useState(false);
  const [shouldButtonDisable, setShouldButtonDisable] = useState(false);
  const [allDataWithCheckedValue, setAllDataWithCheckedValue] = useState([]); // needed for search

  useEffect(() => {
    let alldata = Array.isArray(fieldValueData) ? [...fieldValueData] : [];

    //edge cases
    //if column is enabled change its data to active and inactive for true and false respectively
    if (
      openPopOver?.dispatchName === "enabled" ||
      openPopOver?.dispatchName === "visibility_enabled" ||
      openPopOver?.dispatchName === "required_enabled" ||
      openPopOver?.dispatchName === "show_on_home_page" ||
      openPopOver?.dispatchName === "enable"
    ) {
      alldata = alldata.map((data) => {
        return { ...data, data1: data?.data ? t("active") : t("inActive") };
      });
      //sorting data alphabetically
      alldata?.sort((a, b) =>
        a?.data1 > b?.data1 ? 1 : b?.data1 > a?.data1 ? -1 : 0,
      );
    } else {
      //sorting data alphabetically
      alldata?.sort((a, b) =>
        a?.data > b?.data ? 1 : b?.data > a?.data ? -1 : 0,
      );
    }

    setAllChecked(checkIfAllIsChecked(alldata));
    setShowData(alldata);
    setAllDataWithCheckedValue(alldata);
  }, [fieldValueData, openPopOver?.dispatchName]);

  useEffect(() => {
    setFilterText("");
    setAllChecked(false);
  }, [openPopOver?.dispatchName]);

  const handleClose = () => {
    setShouldButtonDisable(false);
    setFilterText("");
    setOpenPopOver({ ...openPopOver, open: false });
  };

  function handleAscendingDescending(sortOrder) {
    //when ascending button is clicked
    const data = {
      ...dataState,
      filtersQueryString: previousColumnFilterData || [],
      sortBy: openPopOver?.dispatchName,
      sortOrder,
      limit: dataState?.limit,
      page: 1,
      latest_action: "sort",
    };
    setDataState(data);
    handleClose();
  }

  const handleFilterChange = (e) => {
    //when we search the values
    setFilterText(e.target.value);
    let searchedValue = [];

    //if column is enabled search for active and incactive instead of true or false

    searchedValue =
      openPopOver?.dispatchName === "enabled" ||
      openPopOver?.dispatchName === "visibility_enabled" ||
      openPopOver?.dispatchName === "required_enabled" ||
      openPopOver?.dispatchName === "enable"
        ? allDataWithCheckedValue?.filter((str) =>
            String(str?.data1)
              .toLowerCase()
              ?.includes(e.target.value?.toLowerCase()),
          )
        : allDataWithCheckedValue?.filter((str) =>
            String(str?.data)
              .toLowerCase()
              ?.includes(e.target.value?.toLowerCase()),
          );
    setAllChecked(checkIfAllIsChecked(searchedValue));
    setShouldButtonDisable(!checkIfNoneIsSelected(searchedValue));
    setShowData(searchedValue);
  };

  const checkIfAllIsChecked = (dataArray) => {
    //to check if all the checkboxes are selected
    let allChecked = true;
    if (dataArray.length < 1) {
      return false;
    }
    dataArray?.map((data, i) => {
      if (!data?.checked) {
        allChecked = false;
      }
      return false;
    });
    return allChecked;
  };

  const checkIfNoneIsSelected = (dataArray) => {
    //to check if none of the checkboxes are selected
    let allChecked = false;
    dataArray?.map((data, i) => {
      if (data?.checked) {
        allChecked = true;
      }
      return false;
    });
    return allChecked;
  };

  const changeValueBasedOnSecondArray = (array1, array2) => {
    for (let i = 0; i < array1.length; i++) {
      const object2 = array2.find((object) => object.data === array1[i].data);
      if (object2) {
        array1[i] = object2;
      }
    }
    return array1;
  };

  const handleAllClicked = (e, bool) => {
    //when all checkbox is clicked
    let allData;
    let allCheckValueData;

    if (bool) {
      allData = showData?.map((data, i) => {
        return { ...data, checked: true };
      });
      if (filterText.length > 0) {
        allCheckValueData = changeValueBasedOnSecondArray(
          allDataWithCheckedValue,
          allData,
        );
      } else {
        allCheckValueData = allDataWithCheckedValue.map((data, i) => {
          return { ...data, checked: true };
        });
      }
      setAllChecked(true);
    } else {
      allData = showData?.map((data, i) => {
        return { ...data, checked: false };
      });
      if (filterText.length > 0) {
        allCheckValueData = changeValueBasedOnSecondArray(
          allDataWithCheckedValue,
          allData,
        );
      } else {
        allCheckValueData = allDataWithCheckedValue.map((data, i) => {
          return { ...data, checked: false };
        });
      }

      setAllChecked(false);
    }
    setShouldButtonDisable(!checkIfNoneIsSelected(allData));
    setAllDataWithCheckedValue(allCheckValueData);
    setShowData(allData);
  };

  const checkboxHandler = (id) => {
    //changing checkbox value
    const modifiedCheckboxesArray = showData.map((data) => {
      if (data.id === id) {
        data = { ...data, checked: !data.checked };
      }
      return data;
    });
    const modifiedAllCheckboxesArray = allDataWithCheckedValue.map((data) => {
      if (data.id === id) {
        data = { ...data, checked: !data.checked };
      }
      return data;
    });
    setShowData(modifiedCheckboxesArray);
    setAllDataWithCheckedValue(modifiedAllCheckboxesArray);
    setAllChecked(() => checkIfAllIsChecked(modifiedCheckboxesArray));
    setShouldButtonDisable(
      () => !checkIfNoneIsSelected(modifiedCheckboxesArray),
    );
  };

  function handleApplyFilter() {
    //When applying filter
    const checkedBoxes = [];
    showData.map((data) => {
      if (data?.checked) {
        checkedBoxes?.push(data?.data);
      }
      return false;
    });
    if (checkedBoxes.length === 0) {
      return false;
    }
    const prevArray = Array.isArray(previousColumnFilterData)
      ? [...previousColumnFilterData]
      : [];

    //checking if current column name is already in array
    //if yes then replace that obj with new one
    //if no than add new obj in current array
    let arrayHaveName = false;

    let modifiedArray = prevArray.map((obj) => {
      if (obj?.column_name === openPopOver?.dispatchName) {
        obj = {
          column_name: openPopOver?.dispatchName,
          values: checkedBoxes,
        };
        arrayHaveName = true;
      }
      return obj;
    });
    if (!arrayHaveName) {
      modifiedArray = [
        ...prevArray,
        {
          column_name: openPopOver?.dispatchName,
          values: checkedBoxes,
        },
      ];
    }

    const data = {
      ...dataState,
      sortBy: dataState?.sortBy,
      sortOrder: dataState?.sortOrder,
      limit: dataState?.limit,
      page: 1,
      latest_action: "filter",
      filtersQueryString: modifiedArray,
    };
    setDataState(data);
    handleClose();
  }

  function handleClearFilter() {
    // clearing the single filter

    const newFiltersQueryString = dataState?.filtersQueryString.map(
      (obj, i) => {
        if (obj?.column_name === openPopOver?.dispatchName) {
          return null; // Mark the object for removal
        }
        return obj;
      },
    );

    // Create a new state object with the modified filtersQueryString
    const newState = {
      ...dataState,
      page: 1,
      sortBy: "createdAt",
      sortOrder: "desc",
      // latest_action: "",
      filtersQueryString: newFiltersQueryString.filter((obj) => obj !== null),
    };

    setDataState(newState);
    setPreviousColumnFilterData();
    setFilterText("");
    setAllChecked(false);
    handleClose();
  }

  return (
    <Popper
      anchorEl={openPopOver?.open}
      open={openPopOver?.open && true}
      onClose={handleClose}
      disableRestorefocus
      disableScrollLock
      placement="bottom-start"
      sx={{ zIndex: 2 }}
    >
      <ClickAwayListener onClickAway={handleClose}>
        <CheckBoxContainer>
          <Box
            className={`main ${i18n.language} ${
              openPopOver?.dispatchName && openPopOver?.dispatchName
            } `}
          >
            <span className="title_class">{id}</span>{" "}
            <Box className="closeIcon">
              <Close onClick={handleClose} />
            </Box>
            <Box className="upper_buttons">
              <Box className="filterSortBy">
                <label>{t("sortBy")}</label>
                <Box className="buttonsAD">
                  <CustomButton
                    onClick={() => {
                      handleAscendingDescending("asc");
                    }}
                  >
                    <img src={filterImgA} alt="filterIcon" />
                    {t("ascending")}
                  </CustomButton>
                  <CustomButton
                    onClick={() => {
                      handleAscendingDescending("desc");
                    }}
                  >
                    <img src={filterImg} alt="filterIcon" /> {t("descending")}
                  </CustomButton>
                </Box>
              </Box>
            </Box>
            <Box className="filterSearch">
              <label> {t("filterBy")}</label>
              <TextField
                placeholder={t("search")}
                size="small"
                variant="outlined"
                type="text"
                name="filter_text"
                onChange={handleFilterChange}
                value={filterText}
              />
              <Box className="searchIcon">
                <Search />
              </Box>
            </Box>
            <Box className="allFilterValus">
              <Box className="filtersChecks">
                <Box className="selectAll">
                  <Checkbox onChange={handleAllClicked} checked={allChecked} />
                  <label> {t("all")}</label>
                </Box>
                <Box className="allFilterValue">
                  <FormGroup>
                    {showData?.length === 0 ? (
                      <span className="noData"> {t("noDataFound")}</span>
                    ) : (
                      showData?.map((value, index) => (
                        <FormControlLabel
                          key={index}
                          label={
                            openPopOver?.dispatchName === "enabled" ||
                            openPopOver?.dispatchName ===
                              "visibility_enabled" ||
                            openPopOver?.dispatchName === "required_enabled" ||
                            openPopOver?.dispatchName === "show_on_home_page" ||
                            openPopOver?.dispatchName === "enable"
                              ? value?.data1
                              : value?.data
                          }
                          control={
                            <Checkbox
                              onChange={() => checkboxHandler(value?.id)}
                              value={value?.data}
                              checked={value?.checked}
                            />
                          }
                        />
                      ))
                    )}
                  </FormGroup>
                </Box>
              </Box>
            </Box>
          </Box>

          <DialogActions className="popup-button-outer">
            <CustomButton variant={"contained"} onClick={handleClose}>
              {t("cancel")}
            </CustomButton>
            <CustomButton
              variant={"contained"}
              onClick={handleApplyFilter}
              disabled={shouldButtonDisable}
            >
              {t("ok")}
            </CustomButton>
            <CustomButton variant={"contained"} onClick={handleClearFilter}>
              {t("clearFilter")}
            </CustomButton>
          </DialogActions>
        </CheckBoxContainer>
      </ClickAwayListener>
    </Popper>
  );
};

export default CustomComponentFilter;
