import { createSlice } from "@reduxjs/toolkit";
import {
  addCustomTagData,
  createCustomtag,
  customTagRequiredCountApi,
  customTagUpdateApi,
  customTagVisibilityCountApi,
  customTagdeleteApi,
  customtagEditApi,
  deleteCustomTagData,
  getAllCustomTagDetail,
  getCustomTagDetail,
  getCustomTagDetailUser,
  getCustomTags,
  getCustomTagsByid,
  getCustomTagsDataFields,
  getCustomTagsFields,
  updateCustomTagData,
} from "./customTagAction";

const initialState = {
  customTagData: [],
  tagsById: [],
  tagDetailData: [],
  tagDeatilDataAll: [],
  fieldValues: [],
  fieldValuesData: [],
  customTagAllDataUser: [],
};

const customTagSlice = createSlice({
  name: "customTagData",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getCustomTags.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getCustomTags.fulfilled, (state, action) => {
      state.status = "idle";
      state.customTagData = action.payload;
    });
    builder.addCase(createCustomtag.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(createCustomtag.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(customTagdeleteApi.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(customTagdeleteApi.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(customtagEditApi.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(customtagEditApi.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(getCustomTagsByid.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getCustomTagsByid.fulfilled, (state, action) => {
      state.status = "idle";
      state.tagsById = action?.payload;
    });
    builder.addCase(customTagUpdateApi.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(customTagUpdateApi.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(customTagVisibilityCountApi.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(customTagVisibilityCountApi.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(customTagRequiredCountApi.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(customTagRequiredCountApi.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(getCustomTagDetail.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getCustomTagDetail.fulfilled, (state, action) => {
      state.status = "idle";
      state.tagDetailData = action?.payload;
    });

    //getCustomTagDetailUser
    builder.addCase(getCustomTagDetailUser.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getCustomTagDetailUser.fulfilled, (state, action) => {
      state.status = "idle";
      state.customTagAllDataUser = action?.payload;
    });

    builder.addCase(getAllCustomTagDetail.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(getAllCustomTagDetail.fulfilled, (state, action) => {
      state.status = "idle";
      state.tagDeatilDataAll = action?.payload;
    });
    builder.addCase(addCustomTagData.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(addCustomTagData.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(updateCustomTagData.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(updateCustomTagData.fulfilled, (state, action) => {
      state.status = "idle";
    });
    builder.addCase(deleteCustomTagData.pending, (state) => {
      state.status = "loading";
    });
    builder.addCase(deleteCustomTagData.fulfilled, (state, action) => {
      state.status = "idle";
    });
    //filter
    builder
      .addCase(getCustomTagsFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCustomTagsFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      });
    //filter customtagsdata
    builder
      .addCase(getCustomTagsDataFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCustomTagsDataFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValuesData = action.payload;
      });
  },
});

export const allCustomtagNames = (state) => state?.customtags?.customTagData;
export const tagsByidData = (state) => state?.customtags?.tagsById;
export const tagdetails = (state) => state?.customtags?.tagDetailData;
export const tagdetailsAll = (state) => state?.customtags?.tagDeatilDataAll;

export const customTagsFieldValueArray = (state) => {
  return state?.customtags?.fieldValues?.custom_tag;
};

export const customTagsDataFieldValueArray = (state) => {
  return state?.customtags?.fieldValuesData?.custom_tag;
};

export const customTagAllDataUser = (state) =>
  state?.customtags?.customTagAllDataUser;

export default customTagSlice.reducer;
