import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { IconButton } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddEditSegmentLinkPopup from "../AddEditSegmentLinkPopup.jsx/AddEditSegmentLinkPopup";
import MarketLinksList from "./MarketLinksList/MarketLinksList";

const SegmentColumn = (props) => {
  const { listData, title, index, id } = props;
  const [openAddPopup, setOpenAddPopup] = useState(false);

  function handleAddPageToColumn() {
    setOpenAddPopup(true);
  }

  return (
    <Draggable draggableId={title} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className={`dragColumnMain ${
            snapshot.isDragging ? "columnDraggingOn" : "columnDraggingOff"
          }`}
        >
          <div className="hedingCardDrag">
            <div isDragging={snapshot.isDragging} className="columnHeader">
              <div
                isDragging={snapshot.isDragging}
                {...provided.dragHandleProps}
                className="columnTitle"
              >
                <h2>{title}</h2>
              </div>
              <div className="addPageToColumn">
                <IconButton onClick={handleAddPageToColumn}>
                  <AddIcon />
                </IconButton>
              </div>
            </div>
          </div>
          <MarketLinksList
            listId={id}
            listType="List"
            listData={listData}
            internalScroll={props.isScrollable}
            isCombineEnabled={Boolean(props.isCombineEnabled)}
            {...props}
          />
          {openAddPopup && (
            <AddEditSegmentLinkPopup
              openAddPopup={openAddPopup}
              setOpenAddPopup={setOpenAddPopup}
              id={id}
            />
          )}
        </div>
      )}
    </Draggable>
  );
};

export default SegmentColumn;
