import React from "react";
import { useTranslation } from "react-i18next";
import {
  DialogActions,
  DialogContent,
  DialogContentText,
  FormControl,
  MenuItem,
  Select,
} from "@mui/material";
import DialogCommon from "components/DialogComponent/DialogCommon";
import CustomButton from "components/Button/CustomButton";
import CommonDeletePopup from "pages/Admin-Pages/AdminComponents/CommonDeletePopup/CommonDeletePopup";

const ReassignUploadedCollection = (props) => {
  const {
    open,
    showReassign,
    setShowReassign,
    allMainTableData,
    selectValue,
    setSelectValue,
    currentRowData,
    setOpen,
    rowsToBeDeleted,
    setSelectedRows,
    selectedRows,
    handleDeleteAndReassign,
    setRowsToBeDeleted,
  } = props;

  function handleOnlyDelete() {
    handleDeleteAndReassign([currentRowData?._id]);
    handleClose();
  }

  function handleDeleteWithReassign() {
    const deleteIdsArr =
      selectedRows?.length > 0 ? selectedRows : [currentRowData?._id];
    handleDeleteAndReassign(deleteIdsArr, selectValue);
    handleClose();
  }

  const { t } = useTranslation();

  const handleClose = () => {
    setShowReassign(false);
    setSelectedRows();
    setOpen(false);
    setSelectValue("-1");
  };

  const optionsData =
    allMainTableData &&
    rowsToBeDeleted &&
    allMainTableData?.filter(
      (data, i) =>
        !rowsToBeDeleted?.includes(data._id) &&
        data?.enabled &&
        data?._id !== currentRowData?._id,
    );

  return (
    <>
      {optionsData && optionsData?.length <= 0 ? (
        <CommonDeletePopup
          openDelete={open}
          setOpenDelete={setOpen}
          rowsToBeDeleted={rowsToBeDeleted}
          handleDelete={handleOnlyDelete}
          setRowsToBeDeleted={setRowsToBeDeleted}
          multiDeleteText={t("confirmCollectionMultipleDelete")}
          singleDeleteText={t("confirmCollectionSingleDelete")}
        />
      ) : (
        <DialogCommon
          open={open}
          handleClose={handleClose}
          title={t("reassignCollectionAssetsHeading")}
          contentText={t("reassignCollectionAssets")}
          fullWidth={true}
        >
          <DialogActions className={"popup-button-outer"}>
            <CustomButton
              variant="contained"
              className={
                showReassign
                  ? "btn-design-theme yes-button"
                  : "btn-design-theme"
              }
              onClick={() => setShowReassign(true)}
            >
              {t("yes")}
            </CustomButton>
            <CustomButton
              variant="contained"
              className="btn-design-theme cancel-button"
              onClick={() => {
                handleOnlyDelete();
              }}
            >
              {t("no")}
            </CustomButton>
          </DialogActions>
          {showReassign && (
            <div className={"reassignDiv addNewDivSelect"}>
              <DialogContent className="userRoleText">
                <DialogContentText
                  id="alert-dialog-description"
                  className="popup-text"
                >
                  {t(`selectNewCollection`)}
                </DialogContentText>
                <div className={"flexCenter "}>
                  <span> {t("collection")}</span>
                  <FormControl
                    variant="outlined"
                    className={"modalSelect"}
                    size="small"
                  >
                    <Select
                      className="permission-role-select"
                      value={selectValue}
                      onChange={(i) => {
                        setSelectValue(i?.target.value);
                      }}
                    >
                      <MenuItem value={"-1"} className="select-none">
                        {t("select")}
                      </MenuItem>
                      {optionsData?.map((data, i) => {
                        return (
                          <MenuItem key={i} value={data?._id}>
                            {data?.unique_id}
                            {data?.name && `-${data.name}`}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </div>
              </DialogContent>
              <DialogActions className={"popup-button-outer"}>
                <CustomButton
                  variant="contained"
                  className={"btn-design-theme"}
                  onClick={() => handleDeleteWithReassign(currentRowData)}
                >
                  {t("save")}
                </CustomButton>
                <CustomButton
                  variant="contained"
                  className={"btn-design-theme cancel-button"}
                  onClick={handleClose}
                >
                  {t("cancel")}
                </CustomButton>
              </DialogActions>
            </div>
          )}
        </DialogCommon>
      )}
    </>
  );
};

export default ReassignUploadedCollection;
