import { Opacity } from "@mui/icons-material";
import { display, height, maxWidth, positions, styled, width } from "@mui/system";

const UploadSectionStyles = styled("div")(({ theme, disabled = true }) => ({
  display: "grid",
  gap: 16,
  "> span": {
    color: theme.palette.blackColor.main,
  },
  ".uploadSection-buttons": {
    borderRadius: "8px",
    maxWidth: "100%",
    zIndex: "1",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    gap: "15px",
    "> button:first-child": {
      span: {
        color: "#0056B8!important",

        height: '37px',
        alignItems: 'center',
        borderBottom: `2px solid ${theme.palette.primaryColor.main}`,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        maxWidth: '200px',
        display: 'block',
        width: '100%',
        lineHeight: '37px'
      },
      "&:is(:hover, .active)": {
        span: {
          color: theme.palette.primaryColor.main,
        },
        svg: {
          // fill: theme.palette.primaryColor.main,
        },
        "&:after": {
          // background: theme.palette.primaryColor.main,
          // content: "''",
          // position: 'absolute',
          // width: '100%',
          // height: '2px',
          // minWidth: '20px',
          // maxWidth: '73%',
          // left: '50%',
          // transform: 'translate(-55%,-50%)',
          // bottom: '-2px'
        },
      },
    },
    button: {
      "&.MuiButtonBase-root": {
        border: `1px solid ${theme.palette.gray_250.main}`,
        backgroundColor: 'transparent',
        minWidth: 'unset',
        margin: '0',
        fontFamily: 'poppins',
        textTransform: 'unset!important',
        padding: '0px 16px',
        borderRadius: '8px',
        maxWidth: "200px",
        width: "100%",
        height: "37px",
        span: {
          color: theme.palette.blackColor.main,
          marginRight: "8px",
          lineHeight: "37px",
          fontWeight: "500",
          textTransform: "unset !important",
        },
        svg: {
          width: "14px",
          height: "14px",
          path: {
            stroke: theme.palette.blackColor.main,
          }
        },

      },
      "&:first-child": {
        maxWidth: "max-content",
        svg: {
          fill: theme.palette.blackColor.main,
        },

      },
    },
    "span.centerLine": {
      display: "inline",
      margin: "0 28px",
      color: theme.palette.gray_300.main,
    },
    ".dropDownList ": {
      padding: "16px",
      minWidth: "200px",
      width: "100%",
      maxWidth: "185px",
      button: {
        width: "100%",
        maxWidth: "100%",
        background: theme.palette.gray_200.main,
        height: "37px",
        padding: "8px 16px",
        alignItems: "center",
        marginBottom: "16px",
        justifyContent: "flex-start",
        color: theme.palette.blackColor.main,
        border: "0",
        span: {
          color: theme.palette.blackColor.main,
          marginRight: "8px",
          lineHeight: "14px",
        },
        svg: {
          fill: theme.palette.blackColor.main,
          width: "14px",
          height: "14px",
        },
      },
      li: {
        padding: "0 0px 0 0",
        // maxWidth: "calc(100% - 26px)",
        ".valuesBlock": {
          display: 'flex',
          gap: '10px',
          position: "relative",
          span: {
            position: "relative",
            color: theme.palette.blackColor.main,
            whiteSpace: "break-spaces",
            "&.iconBlockDelete": {
              backgroundColor: theme.palette.gray_200.main,
              width: "24px",
              height: "24px",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "8px",
              display: "none",
              position: "absolute",
              right: "0",
              svg: {
                width: "12px",
                height: "12px",
              },
            },
            "&.listDrop": {
              paddingRight: '30px',
            }
          },
          "&:hover": {
            "span.iconBlockDelete": {
              display: "flex",
            }
          },
        },
        "&:not(:last-child)": {
          marginBottom: "16px",
          span: {},
        },
        "&:is(:hover, .active)": {
          backgroundColor: "transparent",
          span: {
            color: theme.palette.primaryColor.main,
          },
        },
      },
    },

    ".addAlbumPopup": {
      left: "0",
      background: "rgba(33, 42, 54, 0.32)",
      ".contributePopupMain": {
        h4: {
          paddingRight: "30px",
        },
      },
      "section.dialog-content": {
        padding: "24px",
      },
      ".addAlbunFooter": {
        button: {
          "&.createAlbum": {
            maxWidth: "100%",
            svg: {
              marginRight: "12px",
              path: {
                stroke: theme.palette.whiteColor.main,
              },
            },
          },
          "&.deleteBtn ": {
            maxWidth: "100%",
            backgroundColor: theme.palette.errorColor.main,
            color: theme.palette.whiteColor.main,
            borderColor: theme.palette.whiteColor.main,
          },

          "&:disabled": {
            svg: {

              path: {
                stroke: theme.palette.gray_500.main,
              },
            },
          }
        },
      },
    },
  },
  "sidebar.uploadSection-sidebar": {
    overflow: "hidden",
    position: "fixed",
    top: "0",
    zIndex: "99",
    right: "0",
    pointerEvents: "none",
    section: {
      width: "100%",
      maxWidth: "400px",
      margin: "0 0 0 auto",
      background: theme.palette.whiteColor.main,
      padding: "32px 8px 32px 32px",
      height: "100vh",
      position: "relative",
      transform: "translateX(100%)",
      transition: "transform 0.2s linear",

      ".sideBarHeader": {
        "> div": {
          padding: "0 0 24px",
          borderBottom: "0",
        },
        ".headerClosePopup": {
          position: "absolute",
          right: "16px",
          top: "16px",
          cursor: "pointer",
        },
      },
      ".collectionData": {
        height: "calc(100vh - 110px)",
        overflow: "auto",
        padding: "0 28px 100px 0",
        "::-webkit-scrollbar-thumb": {
          background: theme.palette.gray_250.main,
          borderRadius: "10px",
        },
        ".imageBlock": {
          borderRadius: "8px",
          overflow: "hidden",
          maxHeight: "224px",
          img: {
            width: "100%",
            height: "224px",
            objectFit: "cover",
          },
        },
        ".sideBarText": {
          marginTop: "25px",
          span: {
            display: "block",
            marginBottom: "16px",
            color: theme.palette.blackColor.main,
          },
        },
        ".footerButton": {
          width: "100%",
          position: "absolute",
          bottom: "36px",
          left: "0",
          padding: "0 25px",
          button: {
            width: "100%",
            maxWidth: "100%",
          },
        },
      },
    },
    ".closeSideBar": {
      position: "fixed",
      width: "100%",
      left: "0",
      height: "100vh",
      background: "rgba(33, 42, 54, 0.32)",
      display: "none",
      transition: "display 0.2s linear",
    },
    "&.openSider": {
      pointerEvents: "unset",
      ".closeSideBar": {
        display: "block",
      },
      section: {
        transform: "translateX(0)",
      },
    },
  },
}));

export default UploadSectionStyles;
