import {
  addcollection,
  deletecollection,
  deleteContributeCollection,
  getAllContributionbyCollectionId,
  Getcollection,
  getCollectionFields,
  getCollectionUser,
  getContributionbyCollectionId,
  getUploadedCollectionFilterFields,
  updateCollections,
  updateContributeCollection,
} from "./collectionAction";

const { createSlice } = require("@reduxjs/toolkit");

const initialState = {
  collectionss: [],
  allCollectionsUser: [],
  fieldValues: [],
  updateDeleteLoading: false,
  uploadedCollectionsData: [],
  allUploadedCollectionData: [],
  uploadedCollectionFields: [],
};

const uploadedCollectionSlice = createSlice({
  name: "uploadedCollectionSlice",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(Getcollection.pending, (state) => {
        state.status = "loading";
      })
      .addCase(Getcollection.fulfilled, (state, action) => {
        state.status = "idle";
        state.collectionss = action.payload;
      });

    //getCollectionUser
    builder
      .addCase(getCollectionUser.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCollectionUser.fulfilled, (state, action) => {
        state.status = "idle";
        state.allCollectionsUser = action.payload;
      });

    builder
      .addCase(updateCollections.pending, (state) => {
        state.status = "loading";
      })
      .addCase(updateCollections.fulfilled, (state, action) => {
        state.status = "idle";
        // state.collectionss=action.payload;
      });
    builder
      .addCase(addcollection.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addcollection.fulfilled, (state, action) => {
        state.status = "idle";
      });
    builder
      .addCase(deletecollection.pending, (state) => {
        state.status = "loading";
      })
      .addCase(deletecollection.fulfilled, (state, action) => {
        state.status = "idle";
      });
    //filter
    builder
      .addCase(getCollectionFields.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCollectionFields.fulfilled, (state, action) => {
        state.status = "idle";
        state.fieldValues = action.payload;
      });
    builder
      .addCase(deleteContributeCollection.pending, (state) => {
        state.updateDeleteLoading = true;
      })
      .addCase(deleteContributeCollection.fulfilled, (state, action) => {
        state.updateDeleteLoading = false;
      })
      .addCase(deleteContributeCollection.rejected, (state) => {
        state.updateDeleteLoading = false;
      });
    builder
      .addCase(updateContributeCollection.pending, (state) => {
        state.updateDeleteLoading = true;
      })
      .addCase(updateContributeCollection.fulfilled, (state, action) => {
        state.updateDeleteLoading = false;
      })
      .addCase(updateContributeCollection.rejected, (state) => {
        state.updateDeleteLoading = false;
      });
    //getContributionbyCollectionId
    builder
      .addCase(getContributionbyCollectionId.pending, (state) => {
        state.updateDeleteLoading = true;
      })
      .addCase(getContributionbyCollectionId.fulfilled, (state, action) => {
        state.updateDeleteLoading = false;
        state.uploadedCollectionsData = action.payload;
      })
      .addCase(getContributionbyCollectionId.rejected, (state) => {
        state.updateDeleteLoading = false;
      });
    //getAllContributionbyCollectionId
    builder
      .addCase(getAllContributionbyCollectionId.pending, (state) => {
        state.updateDeleteLoading = true;
      })
      .addCase(getAllContributionbyCollectionId.fulfilled, (state, action) => {
        state.updateDeleteLoading = false;
        state.allUploadedCollectionData = action.payload;
      })
      .addCase(getAllContributionbyCollectionId.rejected, (state) => {
        state.updateDeleteLoading = false;
      });
    //getUploadedCollectionFilterFields

    builder
      .addCase(getUploadedCollectionFilterFields.pending, (state) => {
        state.updateDeleteLoading = true;
      })
      .addCase(getUploadedCollectionFilterFields.fulfilled, (state, action) => {
        state.updateDeleteLoading = false;
        state.uploadedCollectionFields = action.payload;
      })
      .addCase(getUploadedCollectionFilterFields.rejected, (state) => {
        state.updateDeleteLoading = false;
      });
  },
});

export const collectiostate = (state) =>
  state?.uploadedCollectionSlice?.collectionss;

export const allCollectionsUser = (state) =>
  state?.uploadedCollectionSlice?.allCollectionsUser;

export const collectionFieldValueArray = (state) => {
  return state?.uploadedCollectionSlice?.fieldValues?.asset_collections;
};

export const uploadedCollectionFields = (state) =>
  state?.uploadedCollectionSlice?.uploadedCollectionFields
    ?.contribute_collections;

export const contributeCollectionState = (state) => {
  return state?.uploadedCollectionSlice;
};

export const uploadedCollectionsData = (state) =>
  state.uploadedCollectionSlice.uploadedCollectionsData;

export const allUploadedCollectionsData = (state) =>
  state.uploadedCollectionSlice.allUploadedCollectionData;

export default uploadedCollectionSlice.reducer;
