import React from "react";
import { styled } from "@mui/system";
import ButtonBase from "@mui/material/ButtonBase";

const StyledButton = styled(ButtonBase)(({ theme, disabled = false }) => ({
  "&:not(:nth-last-child(-n+3))": {
    width: "100%",
    maxWidth: "200px",
    padding: "10px 20px",
    "@media only screen and (width >= 1920px)": {
      maxWidth: "296px",
    }
  },
  display: "flex",
  width: "32px",
  height: "32px",
  alignItems: "center",
  padding: "10px 8px",
  fontSize: "12px!important",
  fontFamily: "poppins",
  cursor: disabled ? "not-allowed" : "pointer",
  backgroundColor: disabled ? "#CCCED1" : "#ffffff",
  color: disabled ? "#81868C" : "#212A36",
  borderRadius: "8px",
  transition: "background-color 0.3s ease",
  border: disabled ? `1px solid #CCCED1` : "1px solid #81868C",
  "&:hover": {
    // backgroundColor: disabled && theme.palette.darkBlueColor.main,
  },
  svg: {
    width: "16px",
    height: "16px",
    path: {
      stroke: theme.palette.gray_500.main,
    },
  },
  span: {
    marginLeft: "8px",
  },

  "&.backButtonClass": {
    width: "100%",
    maxWidth: "max-content",
    backgroundColor: theme.palette.gray_200.main,
    borderColor: theme.palette.gray_200.main,
    color: theme.palette.blackColor.main,
    fontWeight: "500",
    padding: "0 12px",
    height: "24px",

    span: {
      marginLeft: "8px",
    },
    "&:hover": {
      backgroundColor: theme.palette.primaryColor.main,
      color: theme.palette.whiteColor.main,
      svg: {
        path: {
          stroke: theme.palette.whiteColor.main,
        },
      },
    },
  },
}));

const ButtonWithFrontIcon = ({
  children,
  icon: Icon,
  disabled,
  onClick,
  refComp,
  className,
}) => {
  return (
    <StyledButton
      ref={refComp}
      disabled={disabled}
      onClick={onClick}
      className={className}
    >
      {Icon && <Icon />}
      {children ?? children}
    </StyledButton>
  );
};

export default ButtonWithFrontIcon;
