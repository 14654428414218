import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import CustomButton from "components/Button/CustomButton";
import { MdDeleteOutline } from "react-icons/md";
import { FaChevronUp, FaChevronDown } from "react-icons/fa";
import {
  createSingleContributeAlbum,
  deleteSingleContributeAlbum,
  getAllContributeAlbums,
} from "../../features/contributionAction";
import { contributerDataState } from "../../features/contributionSlice";
import { ContributePopupStyles } from "components/ContributePopup/contributePopupStyles";
import ContributePopupHeader from "components/ContributePopup/ContributePopupHeader/ContributePopupHeader";
import MainButton, {
  buttonVariants,
  MainButtonWithChildren,
} from "components/Button/MainButton";
import { PopupFooterStyles } from "components/ContributePopup/ContributePopupFooter/ContributePopupFooter";
import ContributePopTextfieldComp from "components/Textfield/ContributePopTextfieldComp";
import ContributePopSectionHeader from "components/ContributePopup/ContributePopupBody/components/ContributePopSectionHeader/ContributePopSectionHeader";
import TextTag, { textVariants } from "components/Typography/TextTag";
import PlusIcon from "components/Icons/PlusIcon/PlusIcon";
import ButtonWithFrontIcon from "components/Button/ButtonWithfrontIcon";
import UploadAssetIcon from "components/Icons/UploadIcon/UploadAssetIcon";

const DeleteAlbumDialog = ({
  openAlbumPopUp,
  closeAlbumPopUp,
  deleteAlbumId,
  dispatch,
  setOpenAlbumsDropdown,
  setSelectedAlbum,
}) => {
  const deleteAlbumHandler = () => {
    dispatch(deleteSingleContributeAlbum({ ids: [deleteAlbumId] }));
    closeAlbumPopUp();
    setOpenAlbumsDropdown(false);
    setSelectedAlbum(t("all"));
  };
  return (
    <ContributePopupStyles className="addAlbumPopup">
      <ClickAwayListener onClickAway={closeAlbumPopUp}>
        <div className="contributePopupMain">
          <ContributePopupHeader
            handlePopupClose={closeAlbumPopUp}
            popupTitle={`${t("areYouDeleteAlbum")} "${openAlbumPopUp}"?`}
          />
          <PopupFooterStyles className="addAlbunFooter">
            <MainButton
              onClick={closeAlbumPopUp}
              variant={buttonVariants.grey}
              title={t("cancel")}
            />
            <MainButton
              onClick={deleteAlbumHandler}
              variant={buttonVariants.grey}
              title={t("delete")}
              className="deleteBtn"
            />
          </PopupFooterStyles>
        </div>
      </ClickAwayListener>
    </ContributePopupStyles>
  );
};

const AddAlbumDialog = ({ closeAlbumPopUp, dispatch }) => {
  const [allbumInputError, setAlbumInputError] = useState(true);
  const albumInputRef = useRef();

  const createAlbumChangeHandler = (event) => {
    const value = event?.target?.value;
    if (!value) {
      setAlbumInputError(true);
    } else if (value.trim() === "") {
      setAlbumInputError(true);
    }
    //  else if (value.length < 3) {
    //   setAlbumInputError("Minumum 3 character required");
    // } else if (value.length > 15) {
    //   setAlbumInputError("Maximum 15 character are allowed");
    // }
    else {
      setAlbumInputError(null);
    }
  };

  const createAlbumHandler = () => {
    const input = albumInputRef.current;
    const value = input?.value.trim();

    if (value && !allbumInputError) {
      dispatch(createSingleContributeAlbum({ name: value }));
      closeAlbumPopUp();
      albumInputRef.current.value = "";
    } else {
      input?.focus();
      input?.style.setProperty("border-color", "red");
      setTimeout(() => {
        input?.style.setProperty("border-color", "");
      }, 2000);
    }
  };

  return (
    <ContributePopupStyles className="addAlbumPopup">
      <ClickAwayListener
        onClickAway={() => {
          closeAlbumPopUp();
          albumInputRef.current.value = "";
        }}
      >
        <div className="contributePopupMain">
          <ContributePopupHeader
            handlePopupClose={() => {
              closeAlbumPopUp();
              albumInputRef.current.value = "";
            }}
            popupTitle={t("addAlbum")}
          />
          <section className="dialog-content">
            <ContributePopSectionHeader
              sectionHeading={t("albumName")}
              isRequired={true}
              tooltipHeading={false}
              tooltipContent={false}
            />
            <ContributePopTextfieldComp
              type="text"
              className="albumInput"
              compRef={albumInputRef}
              onChange={createAlbumChangeHandler}
            />
            {allbumInputError && (
              <span className="error-message">{allbumInputError}</span>
            )}
          </section>
          <PopupFooterStyles className="addAlbunFooter">
            <MainButton
              title={t("cancel")}
              variant={buttonVariants.grey}
              onClick={() => {
                closeAlbumPopUp();
                albumInputRef.current.value = "";
              }}
            />
            <MainButtonWithChildren
              title={t("createAlbum")}
              className="createAlbum"
              variant={buttonVariants.blue}
              onClick={createAlbumHandler}
              disabled={allbumInputError}
            >
              <PlusIcon />
              <span>{t("create")}</span>
            </MainButtonWithChildren>
          </PopupFooterStyles>
        </div>
      </ClickAwayListener>
    </ContributePopupStyles>
  );
};

const AlbumsDropDown = ({
  openAlbumsDropdown,
  closeAlbumsDropdown,
  setHoverAlbumIndex,
  setAlbumPopUpOpen,
  setDeleteAlbumId,
  hoverAlbumIndex,
  anchorRef,
  setSelectedAlbum,
  selectedAlbum,
}) => {
  const { allAlbumsData, allAlbumsLoading } = useSelector(contributerDataState);

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    closeAlbumsDropdown();
  };
  function handleListKeyDown(event) {
    event.preventDefault();
    ["Tab", "Escape"].includes(event?.key) && closeAlbumsDropdown();
  }
  return (
    <Popper
      sx={{ top: "8px !important", left: "16px!important" }}
      open={openAlbumsDropdown}
      anchorEl={anchorRef.current}
      placement="bottom-start"
      transition
      disablePortal
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === "bottom-start" ? "left top" : "left bottom",
          }}
        >
          <Paper sx={{ boxShadow: "0px 4px 15px 0px rgba(0, 0, 0, 0.07);" }}>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList
                sx={{ width: "180px", minHeight: "60px" }}
                autoFocusItem={openAlbumsDropdown}
                id="composition-menu"
                aria-labelledby="composition-button"
                onKeyDown={handleListKeyDown}
                className="dropDownList"
              >
                <MainButtonWithChildren
                  variant={buttonVariants.blue}
                  icon={PlusIcon}
                  className="uploadSection-albumButton"
                  onClick={(e) => {
                    setAlbumPopUpOpen("Add Album");
                    handleClose(e);
                  }}
                >
                  <span>{<PlusIcon />}</span>
                  <TextTag variant={textVariants._14px.medium}>
                    {t("createAlbum")}
                  </TextTag>
                </MainButtonWithChildren>
                <MenuItem
                  onClick={(e) => {
                    setSelectedAlbum(t("all"));
                    handleClose(e);
                  }}
                  className={`uploadSection-menuItems ${selectedAlbum === "all" ? "active" : ""
                    }`}
                >
                  <TextTag variant={textVariants._14px.medium}>
                    {t("all")}
                  </TextTag>
                </MenuItem>
                {allAlbumsData?.albums?.map((item, index) => (
                  <MenuItem
                    key={item._id}
                    onClick={(e) => {
                      setSelectedAlbum(item?.name);
                      handleClose(e);
                    }}
                    className={`uploadSection-menuItems ${selectedAlbum === item?.name ? "active" : ""
                      }`}
                    onMouseEnter={() => setHoverAlbumIndex(index)}
                    onMouseLeave={() => setHoverAlbumIndex(null)}
                  >
                    <div className="valuesBlock">
                      <TextTag variant={textVariants._14px.medium} className="listDrop">
                        {item.name}

                      </TextTag>
                      {/* {index === hoverAlbumIndex && ( */}
                      <span className="iconBlockDelete">
                        <MdDeleteOutline
                          className="deleteIcon"
                          fontWeight={500}
                          onClick={() => {
                            setDeleteAlbumId(item._id);
                            setAlbumPopUpOpen(item.name);
                          }}
                        />
                      </span>
                    </div>
                    {/* )}   */}
                  </MenuItem>
                ))}
              </MenuList>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )
      }
    </Popper >
  );
};

export function AssetsListItems({
  setSelectedAlbum,
  selectedAlbum,
  unMountActiveComponent,
  uploadAssetsHandler,
}) {
  const [openAlbumsDropdown, setOpenAlbumsDropdown] = useState(false);
  const [activeTabElement, setActiveTabElement] = useState(null);
  const [hoverAlbumIndex, setHoverAlbumIndex] = useState(null);
  const [albumPopUpOpen, setAlbumPopUpOpen] = useState(null);
  const [deleteAlbumId, setDeleteAlbumId] = useState(null);
  const prevOpen = useRef(openAlbumsDropdown);
  const selectAllSectionHandler = () => {
    setActiveTabElement(t("all"));
    dispatch(getAllContributeAlbums());
    setOpenAlbumsDropdown(!openAlbumsDropdown);
  };
  const anchorRef = useRef(null);
  const uploadFileRef = useRef(null);
  const dispatch = useDispatch();
  useEffect(() => {
    if (prevOpen?.current === true && openAlbumsDropdown === false) {
      anchorRef?.current?.focus();
    }
    prevOpen.current = openAlbumsDropdown;
  }, [openAlbumsDropdown, activeTabElement]);
  const closeAlbumPopUp = () => setAlbumPopUpOpen(null);
  return (
    <>
      <CustomButton
        onClick={selectAllSectionHandler}
        ref={anchorRef}
        className={activeTabElement === "All" ? "active" : ""}
      >
        <TextTag variant={textVariants._14px.semiBold} className="selectdAssets">
          {selectedAlbum?.name ?? selectedAlbum}
        </TextTag>{" "}
        {openAlbumsDropdown ? <FaChevronUp /> : <FaChevronDown />}
      </CustomButton>
      {/* <span className="centerLine">|</span> */}
      {/* <CustomButton
        onClick={() => setActiveTabElement("SimilarCollection")}
        className={`${activeTabElement === "SimilarCollection" ? "active" : ""
          }`}
      >
        <TextTag variant={textVariants._14px.semiBold}>
          {t("similarColection")}
        </TextTag>
      </CustomButton> */}
      <ButtonWithFrontIcon
        icon={UploadAssetIcon}
        onClick={(e) => {
          e.stopPropagation();
          unMountActiveComponent();
          uploadFileRef.current.click()
        }}
      >
        <input
          type="file"
          id="uploadAssetImages"
          ref={uploadFileRef}
          onChange={(e) => uploadAssetsHandler(e?.target?.files)}
          style={{ display: "none" }}
        />
        <TextTag variant={textVariants._12px.medium}>
          {t("uploadAssets")}
        </TextTag>
      </ButtonWithFrontIcon>
      <AlbumsDropDown
        openAlbumsDropdown={openAlbumsDropdown}
        closeAlbumsDropdown={() => setOpenAlbumsDropdown(false)}
        setHoverAlbumIndex={setHoverAlbumIndex}
        setAlbumPopUpOpen={setAlbumPopUpOpen}
        setDeleteAlbumId={setDeleteAlbumId}
        hoverAlbumIndex={hoverAlbumIndex}
        anchorRef={anchorRef}
        setSelectedAlbum={setSelectedAlbum}
        selectedAlbum={selectedAlbum}
      />
      {albumPopUpOpen === "Add Album" && (
        <AddAlbumDialog closeAlbumPopUp={closeAlbumPopUp} dispatch={dispatch} />
      )}
      {Boolean(albumPopUpOpen) && albumPopUpOpen !== "Add Album" && (
        <DeleteAlbumDialog
          openAlbumPopUp={albumPopUpOpen}
          closeAlbumPopUp={closeAlbumPopUp}
          deleteAlbumId={deleteAlbumId}
          dispatch={dispatch}
          setOpenAlbumsDropdown={setOpenAlbumsDropdown}
          setSelectedAlbum={setSelectedAlbum}
        />
      )}
    </>
  );
}
