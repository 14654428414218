import React from "react";

const FilterIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M8 5.5L12 9.5M12 5.5L8 9.5"
        stroke="#676D75"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.41421 3.08579C5.78929 2.71071 6.29799 2.5 6.82843 2.5H13C14.1046 2.5 15 3.39543 15 4.5V10.5C15 11.6046 14.1046 12.5 13 12.5H6.82843C6.29799 12.5 5.78929 12.2893 5.41421 11.9142L2.41421 8.91421C1.63316 8.13316 1.63317 6.86683 2.41421 6.08579L5.41421 3.08579Z"
        stroke="#676D75"
        stroke-width="1.5"
      />
    </svg>
  );
};

export default FilterIcon;
