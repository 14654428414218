import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { t } from "i18next";
import _ from "lodash";
import ContributePopSectionHeader from "../ContributePopSectionHeader/ContributePopSectionHeader";
import { getAllAssetCategoryApi } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategoryAction";
import { allAssetCategory } from "pages/Admin-Pages/asset-administration/AssetCategory/features/assetCategorySlice";
import SelectComp from "components/Select/SelectComp";
import { categoriesBySubCategories } from "pages/Admin-Pages/asset-administration/AssetSubCategory/features/assetSubCategorySlice";
import { getsubCategoryBasedOnCategory } from "pages/Admin-Pages/asset-administration/AssetSubCategory/features/assetSubCategoryAction";
import { StyledContributePopupBodySection } from "../contributePopupBodyCompStyles";
import TextTag, { textVariants } from "components/Typography/TextTag";

const SelectCategorySubCategory = ({
  selectedCategory,
  setSelectedCategory,
}) => {
  const dispatch = useDispatch();

  const categoryData = useSelector(allAssetCategory).asset_type;
  const subCategoriesData = useSelector(
    categoriesBySubCategories,
  )?.sub_category_data;

  useEffect(() => {
    dispatch(getAllAssetCategoryApi());
  }, [dispatch]);

  const handleCategoryChange = (e) => {
    if (e) {
      setSelectedCategory(e?._id);
      dispatch(getsubCategoryBasedOnCategory(e?._id));
    } else {
      setSelectedCategory("");
    }
  };

  const handleSubCategoryChange = (e) => {
    console.log(e);
  };

  return (
    <>
      <StyledContributePopupBodySection>
        <ContributePopSectionHeader
          sectionHeading={t("category")}
          isRequired={true}
          tooltipHeading={t("category")}
          tooltipContent={t("category")}
        />
        <SelectComp
          selectData={categoryData}
          selectName={"name"}
          selectValue={"_id"}
          onSelectChange={handleCategoryChange}
        />
      </StyledContributePopupBodySection>
      {selectedCategory &&
        (_.isEmpty(subCategoriesData) ? (
          <TextTag
            className={"popupError"}
            variant={textVariants._14px.regular}
          >
            {"No associated sub category found"}
          </TextTag>
        ) : (
          <StyledContributePopupBodySection>
            <ContributePopSectionHeader
              sectionHeading={t("subCategory")}
              isRequired={false}
              tooltipHeading={t("subCategory")}
              tooltipContent={t("subCategory")}
            />
            <SelectComp
              selectData={subCategoriesData}
              selectName={"name"}
              selectValue={"_id"}
              onSelectChange={handleSubCategoryChange}
            />
          </StyledContributePopupBodySection>
        ))}
    </>
  );
};

export default SelectCategorySubCategory;
