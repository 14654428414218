/* eslint-disable */
import { passwordStrength } from "check-password-strength";
import i18n from "Assets/i18n/config";

const t = i18n.t;

export const validationRegex = {
  //list of all the regex used in the website
  checkForDoubleSpaces: /^((?!.* {2}).+)?$/, //       /^(?!.* {2}).+/
  checkForValidEmail: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
  onlyAlphabetsSpace: /^[A-Za-z\s]*$/,
  specialChars: /[`!@#$%^&*()_+\-=\[\]{};':"\|,.<>\/?~]/, //check if there is a special char (eg password)
  numbers: /[0-9]/,
  numbersSpaces: /^([0-9]*)$/,
  lowerAlphabet: /^(?=.*[a-z])/, //check for lowercase alphabets only
  upperAlphabet: /^(?=.*[A-Z])/, //check for uppercase alphabets only
  alphanumeric: /^[a-zA-Z0-9\s]*$/, //to check for alphanumeric values
  exactlyTenDigits: /^\d{10}$/, //eg phone number
  validPhoneNumber: /^\(?(?:[0-9]{3})\)?[-.\s]?([0-9]{3})[-.\s]?([0-9]{4})$/, //matches a specific type of phone number
  parenthesisCheckRemove: /[()\s-]/g, //to remove parenthesis from the phone number
  checkLengthBwEightTwenty: /^.{8,20}$/,
  checkSpace: /\s/g,
  matchOthersExceptSpace: /^\S*$/,
  //regex below contains atleast 1 number, contains atleast 1 special character, length between 8 and 20
  checkPasswordRequirements: /^(?=.*\d)(?=.*[^a-zA-Z\d\s])(.{8,20})$/,
  getFirstCharacterStringSpace: /(^\w|\s\w)/g, //get first character of string or first character after whitespace.
  atleastOneNumberOrAlphabet: /[a-zA-Z0-9]/,
  checkUrl:
    /^((http|https):\/\/)?(www.)?(?!.*(http|https|www.))[a-zA-Z0-9_-]+(\.[a-zA-Z]+)+(\/)?.([\w\?[a-zA-Z-_%\/@?]+)*([^\/\w\?[a-zA-Z0-9_-]+=\w+(&[a-zA-Z0-9_]+=\w+)*)?$/,
};

export const validationMessages = {
  anErrorOccured: t("anErrorOccured"),
  alphanumericOnly: t("alphanumericOnly"),
  selectFile: t("selectFile"),
  noConsecutiveDoubleSpaces: t("noConsecutiveDoubleSpaces"),
  onlyAlphabets: t("onlyAlphabets"),
  pleaseEnterName: t("pleaseEnterName"),
  pleaseEnterFirstName: t("pleaseEnterFirstName"),
  pleaseEnterLastName: t("pleaseEnterLastName"),
  pleaseEnterEmail: t("pleaseEnterEmail"),
  pleaseEnterCaptcha: t("pleaseEnterCaptcha"),
  pleaseEnterPhone: t("pleaseEnterPhone"),
  pleaseEnterPhoneValidFormat: t("pleaseEnterPhoneValidFormat"),
  pleaseEnterAddress: t("pleaseEnterAddress"),
  pleaseEnterUserRole: t("pleaseEnterUserRole"),
  pleaseEnterBusinessUnit: t("pleaseEnterBusinessUnit"),
  pleaseEnterRegion: t("pleaseEnterRegion"),
  pleaseEnterAssetType: t("pleaseEnterAssetType"),
  pleaseEnterAssetCategory: t("pleaseEnterAssetCategory"),
  pleaseEnterAssetSubCategory: t("pleaseEnterAssetSubCategory"),
  pleaseEnterCollection: t("pleaseEnterCollection"),
  pleaseEnterCreatorName: t("pleaseEnterCreatorName"),
  pleaseEnterJobTitle: t("pleaseEnterJobTitle"),
  pleaseEnterPostalCode: t("pleaseEnterPostalCode"),
  pleaseEnterCustomTag: t("pleaseEnterCustomTag"),
  pleaseEnterDescription: t("pleaseEnterDescription"),
  pleaseEnterRightUsageTerms: t("pleaseEnterRightUsageTerms"),
  pleaseEnterCopyRightStatus: t("pleaseEnterCopyRightStatus"),
  pleaseEnterCopyRightNotice: t("pleaseEnterCopyRightNotice"),
  pleaseEnterAssetName: t("pleaseEnterAssetName"),
  pleaseEnterCreditLine: t("pleaseEnterCreditLine"),
  pleaseEnterPageTitle: t("pleaseEnterPageTitle"),
  pleaseEnterPageContent: t("pleaseEnterPageContent"),
  pleaseEnterTertiaryGroup: t("pleaseEnterTertiaryGroup"),
  pleaseEnterSecondaryGroup: t("pleaseEnterSecondaryGroup"),
  pleaseEnterExistingPassword: t("pleaseEnterExistingPassword"),
  pleaseEnterNewPassword: t("pleaseEnterNewPassword"),
  pleaseConfirmNewPassword: t("pleaseConfirmNewPassword"),
  pleaseEnterPassword: t("pleaseEnterPassword"),
  pleaseEnterConfirmPassword: t("pleaseEnterConfirmPassword"),
  pleaseEnterTooltipValue: t("pleaseEnterTooltipValue"),
  pleaseEnterMessage: t("pleaseEnterMessage"),
  pleaseSelectUserRole: t("pleaseSelectUserRole"),
  pleaseSelectAssetCategory: t("pleaseSelectAssetCategory"),
  pleaseSelectAssetSubCategory: t("pleaseSelectAssetSubCategory"),
  pleaseSelectBusinessUnit: t("pleaseSelectBusinessUnit"),
  pleaseSelectRegion: t("pleaseSelectRegion"),
  pleaseSelectCountry: t("pleaseSelectCountry"),
  pleaseSelectState: t("pleaseSelectState"),
  pleaseSelectCity: t("pleaseSelectCity"),
  pleaseSelectAssetType: t("pleaseSelectAssetType"),
  pleaseSelectAssetCollection: t("pleaseSelectAssetCollection"),
  pleaseSelectCreator: t("pleaseSelectCreator"),
  pleaseSelectPageFooterParent: t("pleaseSelectPageFooterParent"),
  pleaseUploadImageUptoOneMB: t("pleaseUploadImageUptoOneMB"),
  pleaseUploadImageInValidFormat: t("pleaseUploadImageInValidFormat"),
  pleaseUploadFile: t("pleaseUploadFile"),
  donotUploadDuplicateFiles: t("donotUploadDuplicateFiles"),
  pleaseAssignUserRoleToUser: t("pleaseAssignUserRoleToUser"),
  pleaseAssignPermissionViewSection: t("pleaseAssignPermissionViewSection"),
  userApprovalNecessary: t("userApprovalNecessary"),
  filesRequired: t("filesRequired"),
  fileCorrupt: t("fileCorrupt"),
  maxWordsAllowed: t("maxWordsAllowed"),
  contentTooLarge: t("contentTooLarge"),
  emailNotVaid: t("emailNotVaid"),
  alreadyRegisteredEmail: t("alreadyRegisteredEmail"),
  tooLong: t("tooLong"),
  passwordResetSuccessfully: t("passwordResetSuccessfully"),
  expiredPasswordLink: t("expiredPasswordLink"),
  newPasswordMeetRequirements: t("newPasswordMeetRequirements"),
  resetPasswordLinkNotValid: t("resetPasswordLinkNotValid"),
  passwordMeetsRequirements: t("passwordMeetsRequirements"),
  makeSurePasswordsMatch: t("makeSurePasswordsMatch"),
  oneSecondaryGroupShouldBeEnabled: t("oneSecondaryGroupShouldBeEnabled"),
  onePrimaryGroupShouldBeEnabled: t("onePrimaryGroupShouldBeEnabled"),
  oneTertiaryGroupShouldBeEnabled: t("oneTertiaryGroupShouldBeEnabled"),
  oneCategoryShouldBeEnabled: t("oneCategoryShouldBeEnabled"),
  oneTypeShouldBeEnabled: t("oneTypeShouldBeEnabled"),
  oneRoleShouldBeEnabled: t("oneRoleShouldBeEnabled"),
  oneSubCategoryShouldBeEnabled: t("oneSubCategoryShouldBeEnabled"),
  noSecondaryGroupAssociated: t("noSecondaryGroupAssociated"),
  noTertiaryGroupAssociated: t("noTertiaryGroupAssociated"),
  twoColumnsMustBeVisible: t("twoColumnsMustBeVisible"),
  pleaseAddSearch: t("pleaseAddSearch"),
  pleaseAddTrendingSearch: t("pleaseAddTrendingSearch"),
  atleastOneNumberOrAlphabet: t("atleastOneNumberOrAlphabet"),
  pleaseSelectWhatToAdd: t("pleaseSelectWhatToAdd"),
  pleaseAddTitle: t("pleaseAddTitle"),
  pleaseSelectPage: t("pleaseSelectPage"),
  pleaseEnterHyperlink: t("pleaseEnterHyperlink"),
  pleaseEnterValidHyperlink: t("pleaseEnterValidHyperlink"),
};

export function checkValidEmail(email) {
  return email.match(validationRegex.checkForValidEmail);
}

export function checkPasswordStrength(password) {
  const regCheckLength = /^.{8,21}$/;

  const passId = passwordStrength(password);

  if (!password || password.length === 0) {
    return { code: 0, message: t("tooShort"), class: "Too Short" };
  }
  if (passId.id === 0) {
    return { code: 1, message: t("weak"), class: "Weak" };
  }
  if (passId.id >= 1) {
    if (regCheckLength.test(password)) {
      if (
        passId.id > 2 &&
        validationRegex.numbers.test(password) &&
        validationRegex.specialChars.test(password) &&
        (validationRegex.upperAlphabet.test(password) ||
          validationRegex.lowerAlphabet.test(password))
      ) {
        return { code: 4, message: t("strong"), class: "Strong" };
      }
      if (
        (validationRegex.upperAlphabet.test(password) ||
          validationRegex.lowerAlphabet.test(password)) &&
        (validationRegex.numbers.test(password) ||
          validationRegex.specialChars.test(password))
      ) {
        return { code: 3, message: t("good"), class: "Good" };
      }
    }
    if (
      regCheckLength.test(password) ||
      validationRegex.numbers.test(password) ||
      validationRegex.specialChars.test(password)
    ) {
      return { code: 2, message: t("medium"), class: "medium" };
    }
    return passwordStrength(password).value;
  }
  return { code: 1, message: t("weak"), class: "Weak" };
}
