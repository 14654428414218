import zIndex from "@mui/material/styles/zIndex";
import { height, styled } from "@mui/system";

const FeaturedProjectStyles = styled("div")(({ theme }) => ({
  fontFamily: "poppins",
  padding: "7.5rem 0 7.5rem 4rem",
  "swiper-container": {
    ".featured_slide_inner": {
      position: "relative",
      overflow: "hidden",
      borderRadius: "10px",
      height: "27.778vw",
      transition: "all 0.2s linear",


      "img.featuredSlideImage": {
        cursor: "pointer",
        width: "100%",
        height: "100%",
        objectFit: "cover",
      },

      ".slideHoverTop": {
        position: "absolute",
        top: "-32px",
        left: "32px",
        transition: "0.2s linear",
        span: {
          color: theme.palette.white.main,
          opacity: "0.4",
        },
      },
      ".hoverSlide": {

        pointerEvents: "none",
        position: "absolute",
        bottom: "0",
        transition: "0.2s linear",
        transform: "translateY(100%)",
        zIndex: "1",
        ".slideHoverBottom": {
          padding: "0 32px 32px",
          color: theme.palette.white.main,
          h3: {
            color: theme.palette.white.main,
            marginBottom: "8px",
          },
          span: {
            color: theme.palette.gray_200.main,
          },
        },
      },
      "&:hover": {
        ".featuredSlideImage": {
          filter: "brightness(0.8)",
        },
        ".slideHoverTop": {
          top: "32px",
        },
        ".hoverSlide": {
          transform: "translateY(0px)",
        },
        "&:after": {
          content: "''",
          position: 'absolute',
          width: '100%',
          height: '100%',
          top: '0',
          left: '0',
          background: 'linear-gradient(180deg, rgba(33, 42, 54, 0.00) 0%, #101419 100%)',
          transition: 'all 0.2s linear',
          borderRadius: '10px',
          zIndex: '0'
        },
      },
    },
  },
}));

export default FeaturedProjectStyles;
