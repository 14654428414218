import React from "react";
import { styled } from "@mui/system";
import { Switch } from "@mui/material";
import { Opacity } from "@mui/icons-material";

export const StyledSwitch = styled(Switch)(({ theme }) => ({
  padding: "0",
  height: "auto",
  ".MuiButtonBase-root": {
    padding: '0',
    top: '2px',
    left: '2px',
    "&.Mui-checked": {
      left: '-5px',
      " + .MuiSwitch-track": {
        backgroundColor: theme.palette.primaryColor.main,
        opacity: "1",
      },
      ".MuiSwitch-thumb": {
        backgroundColor: theme.palette.whiteColor.main,
      },
    },
  },
  ".MuiSwitch-root": {
    padding: '0',
    height: "auto",


  },
  ".MuiSwitch-thumb": {
    width: '18px',
    height: '18px',
  },
  " span.MuiSwitch-track": {
    width: '36px',
    height: '22px',
    borderRadius: '30px',
    backgroundColor: theme.palette.gray_700.main,
    opacity: "1",
  }

}));

const ToggleSwitch = (props) => {
  return <StyledSwitch {...props} />;
};

export default ToggleSwitch;
